import { brandPrimary } from '../../../styles/colors';
import { styleSheet } from './styles';
import { css } from 'aphrodite';
import { PlusIcon } from '../../svgs/icons/PlusIcon';
import * as React from 'react';

interface IProps extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
	className?: string;
}

export class AddBoardItemButton extends React.Component<IProps> {
	public render() {
		const { className = '', children, ...restProps } = this.props;
		return (
			<button {...restProps} className={`${css(styleSheet.dashedButton)} ${className}`}>
				<PlusIcon fillColor={brandPrimary} width={10} height={10} />
				{children}
			</button>
		);
	}
}
