import { css } from 'aphrodite';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { ScheduleSendIcon } from '../../../components/svgs/icons/ScheduleSendIcon';
import { SuccessCheckIcon } from '../../../components/svgs/icons/SuccessCheckIcon';
import { baseStyleSheet } from '../../../styles/styles';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	multiple?: boolean;
}

export const ScheduleSuccess = ({ className, multiple }: IProps) => {
	const fullscreenModal = useFullscreenModal();
	return (
		<div className={`${className ?? undefined}`}>
			<MultiContainerHeader
				fullscreenHeader='Blog Schedule Success'
				onFullscreenRequestClose={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
			/>
			<div className={`${css(styleSheet.container)} blog-schedule-complete ${className || ''}`}>
				<div className={css(styleSheet.iconContainer)}>
					<ScheduleSendIcon />
					<SuccessCheckIcon className={css(styleSheet.successIcon)} />
				</div>
				<div className={css(styleSheet.titleMessage)}>
					<h1>{`Your ${multiple ? 'Blogs have' : 'Blog has'} been scheduled`}</h1>
				</div>
				<button
					className={css(baseStyleSheet.ctaButton, styleSheet.overlayCloseButton)}
					onClick={() => fullscreenModal.dismissModal()}
				>
					Close Window
				</button>
			</div>
		</div>
	);
};
