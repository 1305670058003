import { useQuery } from '@tanstack/react-query';
import { useUserSession } from '../../models/hooks/appStateHooks';

export const GENERATE_CONTACT_AI_CLASSIFICATION = 'contact/classification/generate';

export const useContactAIClassificationGeneration = ({
	enabled = true,
	contactIdsToExclude = [],
}: {
	enabled?: boolean;
	contactIdsToExclude?: string[];
}) => {
	const userSession = useUserSession();

	return useQuery({
		enabled,
		queryFn: () => {
			return userSession.webServiceHelper.callAsync<void>(`contact/classification/suggestions/generate`, 'POST', {
				filterRequest: {
					contactIdsToExclude,
				},
			});
		},
		queryKey: [GENERATE_CONTACT_AI_CLASSIFICATION, contactIdsToExclude[contactIdsToExclude.length - 1]],
		staleTime: 60000 * 60 * 24,
		cacheTime: 60000 * 60 * 24,
	});
};
