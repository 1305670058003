import * as React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { ILocationState, IMultiCampaignSchedulerRequest, IMultiCampaignSchedulerResult } from '../../../models';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { CampaignCalendarSuggestionsProvider } from '../../components/campaigns/CampaignCalendar/context';
import { PrivateRoute } from '../../components/PrivateRoute';
import { ScheduleSuccess } from '../blogs/ScheduleSuccess';
import { MultiBlogScheduler } from './components/blogs/MultiBlogScheduler';
import { MultiBlogSchedulerComplianceConfirmation } from './components/blogs/MultiBlogSchedulerComplianceConfirmation';
import { MultiEmailScheduler } from './components/emails/MultiEmailScheduler';
import { MultiEmailSuggestionsScheduleComplete } from './components/emails/MultiEmailSuggestionsScheduleComplete';
import { MultiSocialCampaignSchedulerComplete } from './components/social/MultiSocialCampaignSchedulerComplete';
import { MultiSocialScheduler } from './components/social/MultiSocialScheduler';

export function MultiCampaignSchedulerRoutes() {
	const userSession = useUserSession();
	const { url } = useRouteMatch();
	const history = useHistory();

	const location = useLocation<ILocationState<any, IMultiCampaignSchedulerRequest>>();
	const request = location.state?.model;

	const onFinishRef = React.useRef(request?.onFinish);

	const onScheduleSuccess = React.useCallback(
		(e: CustomEvent<IMultiCampaignSchedulerResult>) => {
			const { withCompliance } = e.detail;
			if (location.pathname.startsWith(`${url}/email`)) {
				e.preventDefault();
				history.push(`${url}/email/success`);
			} else if (location.pathname.startsWith(`${url}/blogs`)) {
				e.preventDefault();
				history.push({
					pathname: withCompliance ? `${url}/blogs/compliance-confirmation` : `${url}/blogs/success`,
					state: location.state,
				});
			} else if (location.pathname.startsWith(`${url}/social`)) {
				e.preventDefault();
				history.push(`${url}/social/success`);
			}

			onFinishRef.current?.();
		},
		[history, location.pathname, location.state, url]
	);

	return (
		<CampaignCalendarSuggestionsProvider
			initialSelectedSuggestions={request?.suggestions}
			supportsBulkScheduleSuggestions
			onSuccess={onScheduleSuccess}
		>
			<Switch>
				<PrivateRoute path={`${url}/blogs/compliance-confirmation`} userSession={userSession}>
					<MultiBlogSchedulerComplianceConfirmation />
				</PrivateRoute>
				<PrivateRoute path={`${url}/blogs/success`} userSession={userSession}>
					<ScheduleSuccess multiple />
				</PrivateRoute>
				<PrivateRoute path={`${url}/blogs`} userSession={userSession}>
					<MultiBlogScheduler />
				</PrivateRoute>
				<PrivateRoute path={`${url}/email/success`} userSession={userSession}>
					<MultiEmailSuggestionsScheduleComplete />
				</PrivateRoute>
				<PrivateRoute path={`${url}/email`} userSession={userSession}>
					<MultiEmailScheduler />
				</PrivateRoute>
				<PrivateRoute path={`${url}/social/success`} userSession={userSession}>
					<MultiSocialCampaignSchedulerComplete />
				</PrivateRoute>
				<PrivateRoute path={`${url}/social`} userSession={userSession}>
					<MultiSocialScheduler />
				</PrivateRoute>
			</Switch>
		</CampaignCalendarSuggestionsProvider>
	);
}
