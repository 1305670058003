import { StyleSheet } from 'aphrodite';
import {
	background,
	brandPrimaryHover,
	brandPrimaryText,
	brandSecondary,
	destructiveHover,
	error,
	header,
	inputBorderColor,
	label,
	mention,
	navigation,
	titles,
} from '../../../styles/colors';
import { CssSelectors, Layouts } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	addresslineCol: {
		flex: 1,
	},
	addresslineGroup: {
		display: 'flex',
		padding: '0.5rem 0',
		...CssSelectors.allChildren(
			{
				marginLeft: '0.5rem',
			},
			':not(:first-child)'
		),
	},
	buttonContainer: {
		...Layouts.horizontalStack(10),
		marginTop: 20,
	},
	code: {
		display: 'block',
		border: `1px solid ${inputBorderColor}`,
		background,
		padding: 10,
		borderRadius: 5,
		marginBottom: 10,
	},
	checkboxContainer: {
		marginTop: 10,
	},
	checkboxText: {
		marginLeft: 10,
	},
	checkboxTextOnly: {
		marginLeft: 20,
		width: 400,
	},
	dragContainer: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed rgba(0,170,232, 1)`,
		borderRadius: '6px',
		boxShadow: '0px 0px 6px rgba(139,139,139, 0.100034)',
		height: '250px',
		opacity: 1,
		textAlign: 'center',
		width: 510,
	},
	dragFooter: {
		color: brandSecondary,
		fontSize: 12,
	},
	dragInfo: {
		marginTop: '60px',
	},
	dragText: {
		color: brandPrimaryHover,
		fontSize: 18,
	},
	fieldContainerStandard: {
		display: 'flex',
		marginBottom: 30,
	},
	formErrorLine: {
		color: error,
		fontSize: '0.75rem',
	},
	formFieldLabel: {
		color: header,
		display: 'inline-block',
		fontSize: '0.875rem',
		width: 200,
	},
	formFieldValue: {
		color: titles,
		fontSize: '0.875rem',
	},
	formInput: {
		marginBottom: 10,
		width: 300,
	},
	formInputPreview: {
		width: 400,
	},
	formPreviewInput: {
		fontSize: 16,
	},
	label: {
		color: navigation,
		fontSize: 12,
		letterSpacing: 0,
		...CssSelectors.allDescendants(
			{
				color: label,
			},
			'strong'
		),
	},
	labelRequired: {
		'::after': {
			color: destructiveHover,
			content: '"*"',
			display: 'inline',
			marginLeft: '0.25rem',
		},
	},
	purchaseButton: {
		marginTop: 10,
	},
	textAreaScript: {
		width: 600,
		height: 200,
	},
	urlSection: {
		marginTop: 20,
	},
	uploadModalDescription: {
		color: brandSecondary,
		fontSize: 12,
	},
	uploadModalInnerWrap: {
		padding: '38px 0',
	},
	uploadModalRecommendation: {
		color: header,
		fontSize: 12,
		margin: '0 0 12px',
		textAlign: 'right',
	},
	uploadModalSubTitle: {
		color: brandPrimaryText,
		fontSize: 18,
		margin: 0,
	},
	uploadModalTitle: {
		color: brandPrimaryText,
		fontSize: 24,
		margin: 0,
	},
});
