import { StyleSheet } from 'aphrodite';
import { success, white } from '../../../../styles/colors';

export const styleSheet = StyleSheet.create({
	amountText: {
		color: success,
		fontSize: 18,
	},
	contentSection: {
		padding: '12px 20px',
	},
	contentHeader: {
		display: 'flex',
		gap: 8,
	},
	policiesIcon: {
		fill: white,
	},
	contentListTitle: {
		marginTop: 8,
		marginBottom: 24,
	},
	contentAddButton: {
		marginBottom: 10,
		marginLeft: 'auto',
	},
	item: {
		fill: white,
	},
	tableOverrides: {
		marginTop: 16,
		padding: 8,
	},
});
