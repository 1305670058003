import { StyleDeclarationValue, css } from 'aphrodite';
import { computed } from 'mobx';
import { Observer, observer } from 'mobx-react';
import { parse as getQueryString } from 'query-string';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AllRichContentSupportedTypes, RichContentSupportedTypes } from '../../../../../models';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import {
	AppSupportedTimelineTypes,
	ContactViewModel,
	RichContentViewModel,
	TimelineEventTypes,
	UserSessionContext,
} from '../../../../../viewmodels/AppViewModels';
import { ISelectOption, MultiSelect } from '../../../../components/Select';
import { ITabViewChild, ITabbarItem, TabView } from '../../../../components/TabView';
import {
	ContactAutomationsProgressBanner,
	IContactAutomationsProgressBannerComponent,
} from '../../../../components/contacts/ContactAutomationsProgressBanner';
import { ContactConnections } from '../../../../components/contacts/ContactConnections';
import { EntityAnalysis } from '../../../../components/entities/EntityAnalysis';
import { EntityRichContent } from '../../../../components/entities/EntityRichContent';
import { ContactTimeline } from '../../../../components/entities/EntityTimeline/ContactTimeline';
import { AnalysisIcon } from '../../../../components/svgs/icons/AnalysisIcon';
import { ConnectionsIcon } from '../../../../components/svgs/icons/ConnectionsIcon';
import { NotesIcon } from '../../../../components/svgs/icons/NotesIcon';
import { ContactHistory } from './History';
import { QuickActions } from './QuickActions';
import { styleSheet } from './styles';
import { EntityDataBoards } from '../../../../components/dataBoards/EntityDataBoards';

const typesStorageKey = 'timelineFilterOptions';
const richContentStorageKey = 'richContentFilterOptions';

interface IQueryParams {
	tabIndex?: string;
}

export interface IContactActivityComponent {
	showAnalysisTab(): void;
	showRichContentTab(options?: { manageAutomationId?: string }): void;
}

interface IProps extends IEventLoggingComponentProps, RouteComponentProps<any> {
	className?: StyleDeclarationValue[];
	contact?: ContactViewModel;
	innerRef?(ref?: IContactActivityComponent): void;
	scrollToBottomWaypointPortalId?: string;
	userSession?: UserSessionContext;
	setShouldStartPollingForRelationshipUpdate?(poll: boolean): void;
}

interface IState {
	timelineEventTypes?: TimelineEventTypes[];
	richContentTypes?: RichContentSupportedTypes[];
	selectedTabIndex?: number;
}

enum ContactActivityDataContext {
	Analysis = 'Analysis',
	Connections = 'Connections',
	History = 'History',
	RichContent = 'RichContent',
	DataBoards = 'Data Boards',
	Timeline = 'Timeline',
}

export interface ITimelineCriteria {
	name: string;
	type: TimelineEventTypes | 'All' | TimelineEventTypes[];
}

export interface IRichContentCriteria {
	name: string;
	type: RichContentSupportedTypes | 'All';
}

class _ContactActivity extends React.Component<IProps, IState> implements IContactActivityComponent {
	private mContactAutomationsProgressBannerComponentRef: IContactAutomationsProgressBannerComponent;
	private static AllTimelineFilterOptions: ISelectOption<ITimelineCriteria>[];
	private static AllRichContentFilterOptions: ISelectOption<IRichContentCriteria>[];

	public constructor(props: IProps) {
		super(props);
		if (!_ContactActivity.AllTimelineFilterOptions) {
			_ContactActivity.AllTimelineFilterOptions = [
				{
					dataContext: {
						name: 'all',
						type: 'All',
					},
					forceSelectAll: true,
					id: 'rich-content-filter-option-show-all',
					text: 'Show all',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'notes',
						type: 'NoteEvent',
					},
					id: 'rich-content-filter-option-show-notes',
					text: 'Show notes',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'action items',
						type: 'ActionItemEvent',
					},
					id: 'rich-content-filter-option-show-action-items',
					text: 'Show action items',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'emails',
						type: ['SentEmailEvent', 'HtmlNewsletterEvent'],
					},
					id: 'rich-content-filter-option-show-emails',
					text: 'Show emails',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'conversations',
						type: 'ConversationThreadEvent',
					},
					id: 'rich-content-filter-option-show-texts',
					text: 'Show conversations',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'activities outside Levitate',
						type: ['MeetingEvent', 'ExternalSentEmailEvent'],
					},
					id: 'rich-content-filter-option-show-activities-outside-levitate',
					text: 'Show activities outside Levitate',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'phone calls',
						type: 'PhoneCallEvent',
					},
					id: 'rich-content-filter-option-show-phone-calls',
					text: 'Show phone calls',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'surveys',
						type: 'SurveyResponseEvent',
					},
					id: 'rich-content-filter-option-show-surveys',
					text: 'Show surveys',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'handwritten cards',
						type: 'HandwrittenCardOrderEvent',
					},
					id: 'rich-content-filter-option-show-handwritten-cards',
					text: 'Show handwritten cards',
					type: 'checkbox',
				},
			];
		}
		if (!_ContactActivity.AllRichContentFilterOptions) {
			_ContactActivity.AllRichContentFilterOptions = [
				{
					dataContext: {
						name: 'all',
						type: 'All',
					},
					forceSelectAll: true,
					id: 'rich-content-filter-option-show-all',
					text: 'Show all',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'emails',
						type: 'EmailNote',
					},
					id: 'rich-content-filter-option-show-emails',
					text: 'Show emails',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'notes',
						type: 'Note',
					},
					id: 'rich-content-filter-option-show-notes',
					text: 'Show notes',
					type: 'checkbox',
				},
				{
					dataContext: {
						name: 'action items',
						type: 'ActionItem',
					},
					id: 'rich-content-filter-option-show-action-items',
					text: 'Show action items',
					type: 'checkbox',
				},
			];
		}

		this.state = {
			selectedTabIndex: 0,
		};
	}

	public componentDidMount() {
		const { innerRef, location } = this.props;
		if (innerRef) {
			innerRef(this);
		}

		const params: IQueryParams = getQueryString(location.search);
		if (params.tabIndex) {
			const tab = parseInt(params.tabIndex, 10);
			if (!isNaN(tab)) {
				this.setState({ selectedTabIndex: tab });
			}
		}
		this.getRichContentSupportedTypes();
		this.getTimelineSupportedTypes();
	}

	public componentWillUnmount() {
		const { innerRef } = this.props;
		if (innerRef) {
			innerRef(null);
		}
	}

	public render() {
		const { selectedTabIndex } = this.state;
		const { className } = this.props;
		return (
			<div className={css(styleSheet.contactActivity, className)}>
				<TabView
					className={css(styleSheet.contactActivityTabview)}
					contentClassName={css(styleSheet.contactActivityTabviewContent)}
					onSelectedTabIndexChanged={this.onSelectedTabIndexChanged}
					scrollViewContentClassName={css(styleSheet.contactActivityTabviewScrollerContent)}
					selectedTabIndex={selectedTabIndex}
				>
					{this.getTabViewContent}
				</TabView>
			</div>
		);
	}

	public showAnalysisTab = () => {
		this.showTabWithDataContext(ContactActivityDataContext.Analysis);
	};

	public showRichContentTab = (options?: { manageAutomationId: string }) => {
		this.showTabWithDataContext(ContactActivityDataContext.RichContent);
		if (options?.manageAutomationId) {
			this.mContactAutomationsProgressBannerComponentRef?.showManageModal(options.manageAutomationId);
		}
	};

	private showTabWithDataContext = (dataContext: ContactActivityDataContext) => {
		const index = this.tabbarItems.findIndex(x => x.dataContext === dataContext);
		if (index >= 0) {
			this.setState({
				selectedTabIndex: index,
			});
		}
	};

	@computed
	private get tabbarItems() {
		const { userSession } = this.props;
		const canViewContactHistory =
			!!userSession.user.emailProviderFeatures.calendar.canViewHistoryByContact ||
			!!userSession.user.emailProviderFeatures.email.canViewHistoryByContact;
		const shouldShowTimeline = !!userSession.account.features.timeline?.enabled;
		const tabbarItems: ITabbarItem<ContactActivityDataContext>[] = [];

		if (shouldShowTimeline) {
			tabbarItems.push({
				content: this.getTabbarItemContent('History', <NotesIcon />),
				dataContext: ContactActivityDataContext.Timeline,
			});
		} else {
			tabbarItems.push({
				content: this.getTabbarItemContent('History', <NotesIcon />),
				dataContext: ContactActivityDataContext.RichContent,
			});
			if (canViewContactHistory) {
				tabbarItems.push({
					content: this.getTabbarItemContent('Analysis', <AnalysisIcon />),
					dataContext: ContactActivityDataContext.Analysis,
				});
			}
		}

		if (userSession.userRole !== 'limitedUser') {
			tabbarItems.push({
				content: this.getTabbarItemContent('Connections', <ConnectionsIcon />),
				dataContext: ContactActivityDataContext.Connections,
			});
		}

		const { policiesEnabled, donationsEnabled, opportunitiesEnabled } = userSession.account.features?.boards ?? {};
		if (policiesEnabled || donationsEnabled || opportunitiesEnabled) {
			tabbarItems.push({
				content: this.getTabbarItemContent('Data Boards', <ConnectionsIcon />),
				dataContext: ContactActivityDataContext.DataBoards,
			});
		}

		return tabbarItems;
	}

	private getTabbarItemContent(title: string, icon: React.ReactNode) {
		return (
			<span title={title}>
				<span className={css(styleSheet.contactActivityTabviewTabbarItemIcon)}>{icon}</span>
				<span className={css(styleSheet.contactActivityTabviewTabbarItemTitle)}>{title}</span>
			</span>
		);
	}

	private getTabViewContent = (): ITabViewChild[] => {
		const { scrollToBottomWaypointPortalId, userSession, contact, setShouldStartPollingForRelationshipUpdate } =
			this.props;
		const { timelineEventTypes, richContentTypes } = this.state;
		const tabs: ITabViewChild[] = [];

		this.tabbarItems.forEach(x => {
			switch (x.dataContext) {
				case ContactActivityDataContext.Timeline: {
					tabs.push({
						content: (
							<Observer>
								{() => {
									const showAutomation = contact?.inProgressAutomations?.length > 0;
									return (
										<>
											<QuickActions contact={contact.toJs()} />

											{showAutomation && (
												<ContactAutomationsProgressBanner
													contact={contact}
													onRef={this.onContactAutomationsProgressBannerComponentRef}
												/>
											)}
											<div className={css(styleSheet.timelineFilterOptionsContainer)}>
												<MultiSelect
													onClose={this.onTimelineFilterClose}
													options={_ContactActivity.AllTimelineFilterOptions}
													dropdownStyles={[styleSheet.dropdown]}
													selectedOptions={this.getDefaultTimelineOptions()}
													selectAllIfNoneSelected={false}
													optionsStyle={{ maxHeight: 'auto' }}
													selectedOptionsTitle={this.getFilterOptionsTitle}
												/>
											</div>
											<ContactTimeline
												contactId={contact.id}
												emailAddresses={contact.emailAddresses}
												onDeleteRichContent={(richContent: RichContentViewModel) =>
													contact.deleteRichContent(richContent)
												}
												eventTypes={timelineEventTypes}
												scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
												setShouldStartPollingForRelationshipUpdate={setShouldStartPollingForRelationshipUpdate}
											/>
										</>
									);
								}}
							</Observer>
						),
						tabbarItem: x,
					});
					break;
				}
				case ContactActivityDataContext.RichContent: {
					tabs.push({
						content: (
							<Observer>
								{() => {
									return (
										<>
											<div className={css(styleSheet.richContentFilterOptionsContainer)}>
												<MultiSelect
													onClose={this.onRichContentFilterClose}
													options={_ContactActivity.AllRichContentFilterOptions}
													dropdownStyles={[styleSheet.dropdown]}
													selectedOptions={this.getDefaultRichContentFilterOptions()}
													selectAllIfNoneSelected={true}
													selectedOptionsTitle={this.getFilterOptionsTitle}
												/>
											</div>
											{contact?.inProgressAutomations?.length > 0 && (
												<ContactAutomationsProgressBanner
													contact={contact}
													onRef={this.onContactAutomationsProgressBannerComponentRef}
												/>
											)}
											<EntityRichContent
												entity={contact}
												supportedTypes={richContentTypes}
												scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
											/>
										</>
									);
								}}
							</Observer>
						),
						tabbarItem: x,
					});
					break;
				}
				case ContactActivityDataContext.Analysis: {
					tabs.push({
						content: (
							<EntityAnalysis entity={contact} scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId} />
						),
						tabbarItem: x,
					});
					break;
				}
				case ContactActivityDataContext.Connections: {
					tabs.push({
						content: <ContactConnections contact={contact} />,
						tabbarItem: x,
					});
					break;
				}
				case ContactActivityDataContext.History: {
					tabs.push({
						content: (
							<ContactHistory
								contactId={contact?.id}
								contactName={contact?.name}
								scrollToBottomWaypointPortalId={scrollToBottomWaypointPortalId}
								userSession={userSession}
							/>
						),
						tabbarItem: x,
					});
					break;
				}
				case ContactActivityDataContext.DataBoards: {
					tabs.push({
						content: <EntityDataBoards entityViewModel={contact} />,
						tabbarItem: x,
					});
					break;
				}
				default: {
					break;
				}
			}
		});
		return tabs;
	};

	private getRichContentSupportedTypes = () => {
		const storedTypes = window.localStorage.getItem(richContentStorageKey)?.split('|');
		const richContentTypes =
			!storedTypes || storedTypes.length === 0 || storedTypes.includes('All')
				? AllRichContentSupportedTypes
				: storedTypes.filter(option => option !== 'All');

		this.setState({
			richContentTypes: richContentTypes as RichContentSupportedTypes[],
		});
	};

	private getTimelineSupportedTypes = () => {
		const storedTypes = window.localStorage.getItem(typesStorageKey)?.split('|');
		const timelineTypes =
			!storedTypes || storedTypes.length === 0 || storedTypes.includes('All')
				? AppSupportedTimelineTypes
				: storedTypes.filter(option => option !== 'All');

		this.setState({
			timelineEventTypes: timelineTypes as TimelineEventTypes[],
		});
	};

	private getDefaultTimelineOptions = (): ISelectOption<ITimelineCriteria>[] => {
		const storedOptionsStr = window.localStorage.getItem(typesStorageKey);
		if (!storedOptionsStr) {
			return _ContactActivity.AllTimelineFilterOptions;
		}
		const storedOptions = storedOptionsStr.split('|');
		if (storedOptions.includes('All')) {
			return _ContactActivity.AllTimelineFilterOptions;
		}
		return _ContactActivity.AllTimelineFilterOptions.filter(option => {
			if (Array.isArray(option.dataContext.type)) {
				return option.dataContext.type.some(x => storedOptions.includes(x));
			}

			return storedOptions.includes(option.dataContext.type);
		});
	};

	private getDefaultRichContentFilterOptions = (): ISelectOption<IRichContentCriteria>[] => {
		const storedOptions = window.localStorage.getItem(richContentStorageKey)?.split('|');
		return _ContactActivity.AllRichContentFilterOptions.filter(option => {
			if (!storedOptions || storedOptions.length === 0) {
				return option;
			}

			return storedOptions.includes(option.dataContext.type);
		});
	};

	private getFilterOptionsTitle(selectedOptions: ISelectOption<ITimelineCriteria | IRichContentCriteria>[]) {
		if (selectedOptions.find(option => option.dataContext.type === 'All')) {
			return <div>Show all</div>;
		}

		const maxCharCount = 25;
		const text = `Show ${selectedOptions.map(option => option.dataContext.name).join(' and ')}`;
		return <div>{text.length > maxCharCount ? `${text.slice(0, maxCharCount)}...` : text}</div>;
	}

	private onContactAutomationsProgressBannerComponentRef = (ref?: IContactAutomationsProgressBannerComponent) => {
		this.mContactAutomationsProgressBannerComponentRef = ref;
	};

	private onRichContentFilterClose = (selectedOptions: ISelectOption<IRichContentCriteria>[]) => {
		const selected = selectedOptions.map(option => option.dataContext.type);
		window.localStorage.setItem(richContentStorageKey, selected.join('|'));
		this.getRichContentSupportedTypes();
	};

	private onTimelineFilterClose = (selectedOptions: ISelectOption<ITimelineCriteria>[]) => {
		let selected: string[] = [];
		selectedOptions.forEach(option => {
			const eventType = option.dataContext.type;
			if (Array.isArray(eventType)) {
				selected = selected.concat(eventType);
				return;
			}
			selected.push(eventType);
		});
		window.localStorage.setItem(typesStorageKey, selected.join('|'));
		this.getTimelineSupportedTypes();
	};

	private onSelectedTabIndexChanged = (selectedTabIndex: number, tabbarItem: ITabbarItem<string>) => {
		this.setState({
			selectedTabIndex,
		});

		this.props.logInput(`${tabbarItem.dataContext}-TabBarItem`, 'Click');
	};
}

const ContactActivityAsObserver = observer(_ContactActivity);
const ContactActivityWithRouter = withRouter(ContactActivityAsObserver);
export const ContactActivity = withEventLogging(ContactActivityWithRouter, 'ContactActivity');
