import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, error } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	containerHeader: {
		marginBottom: 30,
		textAlign: 'center',
		...CssSelectors.allDescendants(
			{
				fontSize: 18,
			},
			'h2'
		),
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
			},
			'p'
		),
		...CssSelectors.allDescendants(
			{
				display: 'inline-flex',
				marginTop: 30,
			},
			'button'
		),
	},
	feedbackLine: {
		color: error,
		fontSize: 14,
		margin: 0,
	},
	feedbackOutput: {
		border: `1px solid ${error}`,
		borderRadius: 5,
		display: 'block',
		padding: 5,
	},
	headerImage: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: 40,
		marginTop: 30,
		width: '100%',
		...CssSelectors.allDescendants(
			{
				width: 260,
			},
			'img'
		),
	},
	linkSupport: {
		color: brandPrimaryHover,
		textDecoration: 'none',
	},
	loading: {
		marginTop: 100,
	},
});
