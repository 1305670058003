import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { useIsAITaggingModeAllowed } from '.';
import { useEventLogging } from '../../../../models/Logging';
import { useErrorMessages, useUserSession } from '../../../../models/hooks/appStateHooks';
import { TagsList } from '../../../components/entities/tags/TagsEditor';
import { Tag as TagComponent } from '../../../components/tags/Tag';
import { bs } from '../../../styles/styles';
import { useContactTagsUtils } from '../../Contact/useContactTagsUtils';
import { styleSheet } from './styles';

type Props = {
	suggestedTags: string[];
	contactVm: Api.ContactViewModel;
};

export const SuggestedTags = ({ suggestedTags, contactVm }: Props) => {
	const NUMBER_TAGS_TO_SHOW = useIsAITaggingModeAllowed() ? 12 : 5;
	const { tags: addedTags, onRequestAddTag, onRequestRemoveTag } = useContactTagsUtils(contactVm);
	const limitedTags = suggestedTags.slice(0, NUMBER_TAGS_TO_SHOW);
	// we dont want to render a tag at the end if a selected tag was in the first five tags
	const dedupedTags = addedTags.filter(({ tag }) => !limitedTags.includes(tag));
	return (
		<div
			className={css(styleSheet.suggestedTags, bs.flex, bs.itemsCenter, bs.flexWrap, bs.gap2, bs.pl3, bs.pt2, bs.pb2)}
		>
			{limitedTags.map(tag => {
				const fullTag = addedTags.find(contactTag => contactTag.tag === tag);
				return (
					<Tag
						key={tag}
						contactVm={contactVm}
						tag={fullTag || { tag }}
						selected={!!fullTag}
						onRequestAddTag={onRequestAddTag}
						onRequestRemoveTag={onRequestRemoveTag}
					/>
				);
			})}
			{dedupedTags.map(fullTag => {
				return (
					<Tag
						key={fullTag.tag}
						contactVm={contactVm}
						tag={fullTag}
						selected
						onRequestAddTag={onRequestAddTag}
						onRequestRemoveTag={onRequestRemoveTag}
					/>
				);
			})}
			<TagsList onRequestAddTag={onRequestAddTag} tagType={Api.ResourceAutoCompleteViewModelType.AccountTag} />
		</div>
	);
};

const Tag = ({
	tag,
	selected,
	onRequestAddTag,
	onRequestRemoveTag,
	contactVm,
}: {
	tag: Api.IAccountTag;
	selected: boolean;
	onRequestAddTag: (accountTag: Api.IAccountTag) => void;
	onRequestRemoveTag: (accountTag: Api.IAccountTag) => void;
	contactVm: Api.ContactViewModel;
}) => {
	const userSession = useUserSession();
	const { logApiError } = useEventLogging();
	const errorMessages = useErrorMessages();
	const automation = React.useMemo(() => {
		if (tag.automationTemplateId) {
			const automationViewModel = new Api.AutomationTemplateViewModel(userSession, {
				id: tag.automationTemplateId,
				templateType: Api.TemplateType.Automation,
			});
			automationViewModel.load()?.catch((err: Api.IOperationResultNoValue) => {
				errorMessages.pushApiError(err);
				logApiError('LoadAutomationFromTag', err);
			});
			return automationViewModel;
		}
	}, [tag, errorMessages, logApiError, userSession]);

	const onStartAutomation = React.useCallback(() => {
		if (!automation) {
			return undefined;
		}
		return (result?: Api.ICreateAutomationRequest) => {
			if (result) {
				automation.startForContacts(result).catch((err: Api.IOperationResultNoValue) => {
					errorMessages.pushApiError(err);
					logApiError('StartAutomationForContacts', err);
				});
				return;
			}
			if (!contactVm) {
				return;
			}

			automation.createAutomationForContact(contactVm, true).catch((err: Api.IOperationResultNoValue) => {
				errorMessages.pushApiError(err);
				logApiError('StartAutomationFromTag', err);
			});
		};
	}, [automation, contactVm, errorMessages, logApiError]);
	return (
		<TagComponent
			automation={automation}
			isSelected={selected}
			onStartAutomationButtonClicked={onStartAutomation}
			onClick={() => (selected ? onRequestRemoveTag(tag) : onRequestAddTag(tag))}
			showSelectionIndicator
			entity={contactVm}
			tagValue={tag}
		/>
	);
};
