import { StyleSheet } from 'aphrodite';
import { background, titles, white } from '../../../styles/colors';
import { truncateTextStyle } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	card: {
		background: white,
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		padding: '32px 24px',
		gap: 16,
		alignItems: 'flex-start',
		borderRadius: 8,
		marginBottom: 'auto',
	},
	cardList: {
		gridTemplateColumns: 'repeat(auto-fill, minmax(min(320px, 100%), 1fr))',
		height: 'calc(100% - 66px)',
		gap: 20,
		display: 'grid',
		padding: 24,
		boxSizing: 'border-box',
		overflow: 'auto',
	},
	cardName: {
		fontSize: 18,
		lineHeight: 1.5,
		color: titles,
		...truncateTextStyle,
		width: '100%',
	},
	grayBackground: {
		background,
	},
	moreMenu: {
		position: 'absolute',
		right: 8,
		top: 8,
		width: 30,
	},
});
