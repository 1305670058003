import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		position: 'relative',
	},
	dashboardContainer: {
		height: '100%',
	},
});
