import { css } from 'aphrodite';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { useFullscreenModal } from '../../../../models/hooks/appStateHooks';
import { AppBarNavigationItemPortalDestinationId } from '../../../components/AppBar';
import { LoadingSpinner } from '../../../components/LoadingSpinner';
import { MultiContainerHeader } from '../../../components/MultiContainerHeader';
import { NavigationBreadcrumbsBar } from '../../../components/NavigationBreadcrumbsBar';
import { SmallGraph } from '../../../components/svgs/graphics/SmallGraphGraphic';
import { DoubleArrowIcon } from '../../../components/svgs/icons/DoubleArrowIcon';
import { brandSecondary } from '../../../styles/colors';
import { INavigationItemProps } from '../../MainContainer';
import { styleSheet } from './styles';

const LazyOpportunitiesContent = React.lazy(() => import(/* webpackPrefetch: true */ './OpportunitiesContent'));

interface IProps extends INavigationItemProps {
	className?: string;
}

export function OpportunitiesBoard({ className, routeContainerClassName }: IProps) {
	const fullscreenModal = useFullscreenModal();
	const match = useRouteMatch<{
		id: string;
	}>();

	const [locationName, setLocationName] = React.useState('Opportunities Board');

	const { id: boardId } = match.params;

	return (
		<div className={`${css(styleSheet.container)} ${routeContainerClassName} ${className}`}>
			<MultiContainerHeader
				appBarHeader={
					<div className={css(styleSheet.breadcrumbsBar)}>
						<NavigationBreadcrumbsBar
							currentLocationName={locationName}
							pathComponentNameProvider={pathComp => {
								switch (pathComp?.toLocaleLowerCase()) {
									case 'databoards': {
										return 'Data Boards';
									}
									case 'opportunities': {
										return null;
									}
									default: {
										return pathComp;
									}
								}
							}}
						/>

						<div className={css(styleSheet.banner)}>
							<SmallGraph />
							<a
								href='#'
								className={css(styleSheet.bannerLink)}
								onClick={ev => {
									ev.preventDefault();
									fullscreenModal.history.push({
										pathname: `/reporting/advanced-opportunity-reporting`,
									});
								}}
							>
								Check our advanced reporting{' '}
								<DoubleArrowIcon fillColor={brandSecondary} style={{ marginLeft: '0.5rem' }} />
							</a>
						</div>
					</div>
				}
				appBarPortalDestinationId={AppBarNavigationItemPortalDestinationId}
				fullscreenHeader={locationName}
			/>

			<React.Suspense fallback={<LoadingSpinner className={css(styleSheet.loading)} type='large' />}>
				<LazyOpportunitiesContent setBreadcrumbLocationName={setLocationName} boardId={boardId} />
			</React.Suspense>
		</div>
	);
}
