import { StyleSheet } from 'aphrodite';
import { brandPrimaryText, tagBackgroundColor, titles } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	banner: {
		alignItems: 'center',
		background: tagBackgroundColor,
		display: 'flex',
		padding: '1rem 0.5rem',
		gap: 12,
	},
	bannerLink: {
		alignItems: 'center',
		color: brandPrimaryText,
		display: 'inline-flex',
		fontWeight: 600,
		fontSize: 14,
		textDecoration: 'none',
	},
	breadcrumbsBar: {
		display: 'flex',
		alignItems: 'center',
		gap: 24,
		paddingRight: 16,
	},
	container: {
		boxSizing: 'border-box',
		width: '100%',
		height: '100%',
		paddingTop: 24,
	},
	infoHeaderContentBold: {
		marginTop: 4,
		marginBottom: 12,
		fontWeight: 600,
	},
	viewOnlyModeBannerContent: {
		display: 'flex',
		fontSize: 14,
		padding: '20px 20px 20px 40px',
		gap: 8,
	},
	viewOnlyModeBannerContainer: {
		justifyContent: 'space-between',
		display: 'flex',
		background: '#FFEDF9',
		borderLeft: '3px solid #78DBFF',
		borderRadius: 3,
		boxSizing: 'border-box',
		color: titles,
		alignItems: 'flex-start',
	},
	loadingRow: {
		height: 300,
	},
});
