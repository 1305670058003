import { brandPrimary } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	automationAnchor: {
		height: 0,
	},
	automationPopover: {
		fontSize: 14,
		letterSpacing: '0',
		padding: '5px 10px',
	},
	automationPopoverMessage: {
		color: '#4a4a4a',
		padding: '5px 12px',
	},
	automationPopoverName: {
		color: brandPrimary,
		padding: '5px 12px',
	},
	automationStartButton: {
		margin: '10px 10px',
		padding: '5px 12px',
	},
	automationXIcon: {
		cursor: 'pointer',
		marginRight: 5,
		textAlign: 'right',
	},
	container: {},
});
