import * as Api from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import {
	DefaultEditableActionItemContentCSS,
	IModalContext,
	ModalChildComponentContextKey,
} from '../../../../../models';
import {
	EnvironmentKey,
	ErrorMessagesViewModelKey,
	IEnvironmentComponentProps,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { EventLogger } from '../../../../../models/Logging';
import {
	convertRawRichTextContentStateToRichContentEditorState,
	getDefaultDateStringValue,
	numberToCurrencyStringValue,
	sanitizeCurrencyStringValue,
} from '../../../../../models/UiUtils';
import { CloseButton } from '../../../CloseButton';
import CompanyThumbUrl from '../../../../assets/companyThumb.svg';
import CalendarIconUrl from '../../../../assets/icon_calendar.svg';
import { baseStyleSheet } from '../../../../styles/styles';
import { Avatar } from '../../../Avatar';
import { DayPicker } from '../../../DayPicker';
import { InputFieldError } from '../../../InputFieldError';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { asModalComponent } from '../../../Modal';
import { Popover, PopoverType } from '../../../Popover';
import { ISelectOption, Select } from '../../../Select';
import { ISelectBoxOption } from '../../../SelectBox';
import { TextInput } from '../../../TextInput';
import {
	IRichContentDocumentEditorConfig,
	RichContentDocumentEditor,
} from '../../../richContent/RichContentDocumentEditor';
import { ClearFieldIcon } from '../../../svgs/icons/ClearFieldIcon';
import { NavIcon } from '../../../svgs/icons/NavIcon';
import { EditOpportunitySearchField } from '../EditOpportunitySearchField';
import { styleSheet } from './styles';
import { white } from '../../../../styles/colors';
import './styles.less';

interface IProps
	extends IModalContext<Api.OpportunityViewModel>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps,
		IEnvironmentComponentProps {
	board: Api.OpportunitiesBoardViewModel;
	className?: string;
	company?: Api.CompanyViewModel;
	primaryContact?: Api.ContactViewModel;
	hideStageOptions?: boolean;
	opportunity?: Api.OpportunityViewModel;
	stage?: Api.OpportunitiesBoardStageViewModel;
	boards?: Api.IBoard[];
}

interface IState {
	amountErrorMessage?: string;
	amountStringValue?: string;
	board?: Api.IBoard;
	closeDate?: Date;
	closingDateErrorMessage?: string;
	company?: Api.CompanyViewModel;
	companyErrorMessage?: string;
	detailsEditorState?: Api.IRichContentEditorState;
	name?: string;
	nameErrorMessage?: string;
	owner?: Api.UserViewModel;
	ownerErrorMessage?: string;
	primaryContact?: Api.ContactViewModel;
	primaryContactErrorMessage?: string;
	selectedStageOption?: ISelectBoxOption<Api.OpportunitiesBoardStageViewModel>;
	showingCloseDatePopover?: boolean;
	stage?: Api.OpportunitiesBoardStageViewModel;
	stageErrorMessage?: string;
	stageOptions?: ISelectBoxOption<Api.OpportunitiesBoardStageViewModel>[];
}

const DefaultDetailsEditorConfig: IRichContentDocumentEditorConfig = {
	autoresizeToFitContent: true,
	contentRawCss: DefaultEditableActionItemContentCSS,
	plugins: [], // none
	toolbar: false,
};

class _EditOpportunity extends React.Component<IProps, IState> {
	public state: IState = {};
	private contactInput: HTMLInputElement;

	public UNSAFE_componentWillMount() {
		const { primaryContact, opportunity, stage, company } = this.props;
		const nextState: IState = {
			stage,
		};
		if (opportunity) {
			nextState.name = opportunity.name;
			nextState.company = opportunity.company;
			nextState.owner = opportunity.owner;
			nextState.primaryContact = opportunity.primaryContact;
			nextState.amountStringValue = numberToCurrencyStringValue(opportunity.dealSize);
			nextState.closeDate = opportunity.closeDate;
			nextState.detailsEditorState = convertRawRichTextContentStateToRichContentEditorState(opportunity.details);
		} else {
			nextState.detailsEditorState = convertRawRichTextContentStateToRichContentEditorState();
			nextState.owner = new Api.UserViewModel(this.props.userSession, this.props.userSession.user);
			nextState.company = company;
			nextState.primaryContact = primaryContact;
		}

		this.setState(nextState);
	}

	public componentDidMount() {
		this.setState({ board: this.props.board });

		if (this.contactInput) {
			this.contactInput.focus();
		}
	}

	public render() {
		const { className, environment, opportunity, boards } = this.props;
		const {
			amountErrorMessage,
			amountStringValue,
			board,
			closeDate,
			closingDateErrorMessage,
			company,
			companyErrorMessage,
			detailsEditorState,
			name,
			nameErrorMessage,
			owner,
			ownerErrorMessage,
			primaryContact,
			primaryContactErrorMessage,
			showingCloseDatePopover,
			stage,
		} = this.state;

		if (!board) {
			return null;
		}

		const stageOptions: ISelectOption<Api.OpportunitiesBoardStageViewModel>[] = board.stages.map(s => ({
			// @ts-ignore
			dataContext: s as Api.OpportunitiesBoardStageViewModel,
			id: s.id,
			text: s.name,
		}));

		const selectedStage = stage
			? {
					dataContext: stage,
					id: stage.id,
					text: stage.name,
				}
			: stageOptions[0];

		const defaultBoardOptions: ISelectOption<Api.IBoard>[] = [{ dataContext: board, id: board.id, text: board.name }];
		const boardOptions: ISelectOption<Api.IBoard>[] = boards
			? boards.map(boardOption => {
					return { dataContext: boardOption, id: boardOption.id, text: boardOption.name };
				})
			: defaultBoardOptions;
		const selectedBoard = boardOptions.find(boardOption => boardOption.id === board.id);

		return (
			<div className={`${className || ''} ${css(styleSheet.container)}`}>
				<div className={css(styleSheet.header)}>
					<NavIcon iconName='Opportunities' fillColor={white} />
					<CloseButton fillColor={white} onClick={this.onRequestClose(null, true)} />
				</div>
				<div className={css(styleSheet.contentContainer)}>
					<div className={css(styleSheet.contentContainerLeft)}>
						<div className={css(styleSheet.title)}>{`${opportunity ? 'Edit' : 'Add New'} Opportunity`}</div>
						<div className={css(styleSheet.body)}>
							<div className={css(styleSheet.bodyContent)}>
								{this.renderFieldLabel('Primary Contact')}
								<InputFieldError errorMessage={primaryContactErrorMessage}>
									<EditOpportunitySearchField
										className={css(styleSheet.primary, styleSheet.focus)}
										hideResultsFooter={false}
										inputId='edit-opportunity-contact-input'
										leftAccessory={
											primaryContact ? (
												<Avatar className='edit-opportunity-body-avatar' entityVm={primaryContact} />
											) : undefined
										}
										onClearButtonClicked={this.onClearPrimaryContactSearchFieldClicked}
										onFocus={this.onInputFocus}
										onInputRef={this.onInputRef}
										onItemSelected={this.onPrimaryContactSelected}
										placeholder='Search contacts'
										showClearButton={!!primaryContact}
										showSearchLeftAccessory={true}
										type={Api.ResourceAutoCompleteViewModelType.Contact}
										value={(primaryContact ? primaryContact.name : null) || ''}
									/>
								</InputFieldError>

								{this.renderFieldLabel('Opportunity Name')}
								<InputFieldError errorMessage={nameErrorMessage}>
									<TextInput
										className={css(styleSheet.field, styleSheet.focus)}
										inputId='edit-opportunity-name-input'
										onChange={this.onTitleChanged}
										onFocus={this.onInputFocus}
										type='text'
										value={name || ''}
									/>
								</InputFieldError>
								<div className={css(styleSheet.subtitle)}>
									This is the main title. It could be a project name or property name.{' '}
								</div>

								{this.renderFieldLabel('Opportunity Owner')}
								<InputFieldError errorMessage={ownerErrorMessage}>
									<EditOpportunitySearchField
										className={css(styleSheet.field, styleSheet.focus)}
										inputId='edit-opportunity-owner-input'
										leftAccessory={owner ? null : undefined}
										onClearButtonClicked={this.onClearOwnerSearchFieldClicked}
										onFocus={this.onInputFocus}
										onItemSelected={this.onOwnerSelected}
										placeholder='Search users'
										showClearButton={!!owner}
										showSearchLeftAccessory={true}
										type={Api.ResourceAutoCompleteViewModelType.User}
										value={(owner ? owner.name : null) || ''}
									/>
								</InputFieldError>

								{this.renderFieldLabel('Opportunity Board', false)}
								{boardOptions.length ? (
									<Select
										styles={[styleSheet.selectStyles]}
										options={boardOptions}
										onOptionClick={this.onBoardSelected}
										selectedOption={selectedBoard}
									/>
								) : (
									<TextInput
										className={css(styleSheet.field)}
										inputId='edit-opportunity-disabled-board'
										type='text'
										disabled
										value={selectedBoard.text}
									/>
								)}

								{this.renderFieldLabel('Opportunity Stage', false)}
								{stageOptions ? (
									<Select
										styles={[styleSheet.selectStyles]}
										options={stageOptions}
										onOptionClick={this.onStageSelected}
										selectedOption={selectedStage}
									/>
								) : (
									<TextInput
										className={css(styleSheet.field)}
										inputId='edit-opportunity-disabled-stage'
										type='text'
										disabled
										value={selectedStage.text}
									/>
								)}

								<div className='edit-opportunity-footer'>
									<button className={css(baseStyleSheet.ctaButton)} onClick={this.onSaveButtonClicked}>
										<span>Save</span>
									</button>
									<button className={css(baseStyleSheet.ctaButtonReverse)} onClick={this.onRequestClose(null, true)}>
										<span>Cancel</span>
									</button>
								</div>
							</div>
							{!!stage && !!stage.isBusy && <LoadingSpinner className='absolute-center' type='large' />}
						</div>
					</div>
					<div className={css(styleSheet.contentContainerRight)}>
						<div className={css(styleSheet.optional)}>optional fields</div>
						{this.renderFieldLabel('Company', false)}
						<InputFieldError errorMessage={companyErrorMessage}>
							<EditOpportunitySearchField
								className={css(styleSheet.company, styleSheet.focus)}
								disabled={!!this.props.company}
								inputId='edit-opportunity-company-input'
								leftAccessory={company ? <img src={CompanyThumbUrl} /> : undefined}
								onClearButtonClicked={this.onClearCompanySearchFieldClicked}
								onFocus={this.onInputFocus}
								onItemSelected={this.onCompanySelected}
								placeholder='Search companies'
								showClearButton={!!company}
								showSearchLeftAccessory={true}
								type={Api.ResourceAutoCompleteViewModelType.Company}
								value={(company ? company.name : null) || ''}
							/>
						</InputFieldError>

						{this.renderFieldLabel('Amount', false, null, [styleSheet.amountTitle])}
						<InputFieldError errorMessage={amountErrorMessage} className={css(styleSheet.inputFieldError)}>
							<TextInput
								className={css(styleSheet.field, styleSheet.focus)}
								inputId='edit-opportunity-amount-input'
								leftAccessory={<span className={css(styleSheet.dollar)}>$</span>}
								onBlur={this.onAmountInputBlur}
								onChange={this.onAmountChanged}
								onFocus={this.onInputFocus}
								placeholder='0.00'
								type='text'
								value={amountStringValue || ''}
							/>
						</InputFieldError>
						{this.renderFieldLabel('Closing Date', false, css(styleSheet.closingDateTitle))}
						<InputFieldError errorMessage={closingDateErrorMessage} className={css(styleSheet.inputFieldError)}>
							<div className={css(styleSheet.closingDate)}>
								<button
									className='edit-opportunity-body-closing-date-field-button'
									onClick={this.onChangeClosingDateClicked}
								>
									<Popover
										anchor={<img src={CalendarIconUrl} />}
										dismissOnClickOutside={true}
										isOpen={showingCloseDatePopover}
										onRequestClose={this.onClosingDateDayPickerRequestClose}
										preferredPlacement='above'
										type={PopoverType.white}
									>
										<div className='edit-opportunity-body-day-picker'>
											<DayPicker
												allowPastDates={true}
												environment={environment}
												onDayClick={this.onClosingDaySelected}
												selectedDays={closeDate}
											/>
										</div>
									</Popover>
									{!!closeDate && <span>{getDefaultDateStringValue(closeDate)}</span>}
								</button>
								<button className='edit-opportunity-body-field-clear-button' onClick={this.onClosingDayClearClicked}>
									<ClearFieldIcon />
								</button>
							</div>
						</InputFieldError>
						{this.renderFieldLabel('Details', false)}
						<div className='edit-opportunity-input-field edit-opportunity-body-details-field'>
							<RichContentDocumentEditor
								contentClassName={css(styleSheet.details)}
								config={DefaultDetailsEditorConfig}
								contentState={detailsEditorState}
								onContentStateChanged={this.onDetailsEditorStateChanged}
								onFocus={this.onInputFocus}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private onInputFocus = () => {
		this.clearErrorMessages();
	};

	private onTitleChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			name: e.target.value,
		});
	};

	private onDetailsEditorStateChanged = (detailsEditorState: Api.IRichContentEditorState) => {
		this.setState({
			detailsEditorState,
		});
	};

	private onClosingDaySelected = (day: Date) => {
		this.setState({
			closeDate: day,
			showingCloseDatePopover: false,
		});
	};

	private onClosingDayClearClicked = () => {
		this.setState({
			closeDate: null,
		});
	};

	private onClosingDateDayPickerRequestClose = () => {
		this.setState({
			showingCloseDatePopover: false,
		});
	};

	private onChangeClosingDateClicked = () => {
		this.setState({
			showingCloseDatePopover: !this.state.showingCloseDatePopover,
		});
		this.clearErrorMessages();
	};

	private onAmountInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		const stringValue = (e.target as HTMLInputElement).value || '0.00';
		const currencyStringValue = sanitizeCurrencyStringValue(stringValue);
		if (currencyStringValue) {
			this.setState({
				amountStringValue: numberToCurrencyStringValue(parseFloat(currencyStringValue)),
			});
		}
	};

	private onAmountChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({
			amountStringValue: e.target.value,
		});
	};

	private clearErrorMessages = () => {
		this.setState({
			amountErrorMessage: null,
			closingDateErrorMessage: null,
			companyErrorMessage: null,
			nameErrorMessage: null,
			ownerErrorMessage: null,
			primaryContactErrorMessage: null,
			stageErrorMessage: null,
		});
	};

	private onSaveButtonClicked = () => {
		const { opportunity } = this.props;
		const { amountStringValue, closeDate, company, detailsEditorState, name, owner, primaryContact, stage } =
			this.state;

		// check for errors
		const requiredFieldErrorMessage = 'is required.';
		const nextState: IState = {};

		if (!primaryContact) {
			nextState.primaryContactErrorMessage = `A primary contact ${requiredFieldErrorMessage}`;
		}

		if (!owner) {
			nextState.ownerErrorMessage = `An owner ${requiredFieldErrorMessage}`;
		}

		if (!name) {
			nextState.nameErrorMessage = `An opportunity name ${requiredFieldErrorMessage}`;
		}

		let targetStage = stage || this.props.stage;
		if (!targetStage) {
			nextState.stageErrorMessage = `An opportunity stage ${requiredFieldErrorMessage}`;
		}
		if (!(targetStage instanceof Api.OpportunitiesBoardStageViewModel)) {
			targetStage = new Api.OpportunitiesBoardStageViewModel(this.props.userSession, targetStage);
		}

		if (Object.keys(nextState).length > 0) {
			this.setState(nextState);
			return;
		}

		const companyId = company ? company.id : primaryContact.companyId;
		const stageId = (stage || this.props.stage || {}).id;
		const opportunityModel: Api.IOpportunity = {
			assignees: [{ id: owner.id }],
			boardStage: { id: stageId },
			closeDate: closeDate ? closeDate.toISOString() : null,
			company: { id: companyId },
			dealSize: parseFloat(sanitizeCurrencyStringValue(amountStringValue)),
			details: detailsEditorState ? detailsEditorState.getRawRichTextContent() : null,
			name,
			primaryContact: { id: primaryContact.id },
		};

		if (!!opportunity && !!opportunity.id) {
			opportunityModel.id = opportunity.id;
			this.saveOpportunity(opportunityModel);
		} else {
			const promise = targetStage.createItem(opportunityModel);
			if (promise) {
				const sparceOpportunityModel: {
					hasDetailsText?: boolean;
				} & Api.IOpportunity = { ...opportunityModel };
				delete sparceOpportunityModel.details;
				delete sparceOpportunityModel.name;
				sparceOpportunityModel.hasDetailsText = detailsEditorState ? !!detailsEditorState.hasContent() : false;
				EventLogger.logEvent(
					{
						action: 'Create',
						category: 'EditOpportunity',
					},
					sparceOpportunityModel
				);
				promise
					.then(editedOpportunity => {
						if (this.props.parentModal) {
							this.props.parentModal.onRequestClose(editedOpportunity as Api.OpportunityViewModel, false);
						}
					})
					.catch((error: Api.IOperationResultNoValue) => {
						EventLogger.logEvent(
							{
								action: 'Create-Error',
								category: 'EditOpportunity',
							},
							{ ...error }
						);
					});
			}
		}
	};

	private saveOpportunity(opportunityModel: Api.IOpportunity) {
		const { opportunity, parentModal, userSession } = this.props;

		const promise = opportunity.save(opportunityModel);
		if (promise) {
			EventLogger.logEvent(
				{
					action: 'Update',
					category: 'EditOpportunity',
				},
				{ id: opportunityModel.id }
			);
			promise
				.then(savedOpportunity => {
					if (savedOpportunity.boardStage) {
						const stage = new Api.OpportunitiesBoardStageViewModel(userSession, savedOpportunity.boardStage);
						// @ts-ignore
						opportunity.setStage(stage);
					}
					if (parentModal) {
						parentModal.onRequestClose(opportunity, false);
					}
				})
				.catch((error: Api.IOperationResultNoValue) => {
					EventLogger.logEvent(
						{
							action: 'Update-Error',
							category: 'EditOpportunity',
						},
						{ ...error }
					);
				});
		}
	}

	private onClearCompanySearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		this.setState({
			company: null,
		});
		e.stopPropagation();
	};

	private onClearPrimaryContactSearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		this.setState({
			primaryContact: null,
		});
		e.stopPropagation();
	};

	private onClearOwnerSearchFieldClicked = (e: React.MouseEvent<HTMLElement>) => {
		this.setState({
			owner: null,
		});
		e.stopPropagation();
	};

	private renderFieldLabel(title: string, required = true, className?: string, styles?: StyleDeclarationValue[]) {
		return (
			<div className={`${css(styleSheet.fieldLabel, ...(styles || []))} ${className || ''}`}>
				{title + ': '}
				{required ? <span>*</span> : null}
			</div>
		);
	}

	private onCompanySelected = (company: Api.ICompany) => {
		const selectedCompany = new Api.CompanyViewModel(this.props.userSession, company);
		this.setState({
			company: selectedCompany,
		});
	};

	private onOwnerSelected = (owner: Api.IUser) => {
		const user = new Api.UserViewModel(this.props.userSession, owner);
		this.setState({
			owner: user,
		});
	};

	private onPrimaryContactSelected = (contact: Api.IContact) => {
		const primaryContact = new Api.ContactViewModel(this.props.userSession, contact);
		this.setState({
			company: new Api.CompanyViewModel(this.props.userSession, {
				companyName: contact.companyName,
				id: contact.companyId,
			}),
			name: contact.firstName + ' ' + contact.lastName,
			primaryContact,
		});
	};

	private onStageSelected = (stage: ISelectOption<Api.OpportunitiesBoardStageViewModel>) => {
		this.setState({
			stage: stage.dataContext,
		});
	};

	private onBoardSelected = (boardOption: ISelectOption<Api.IBoard>) => {
		const board = boardOption.dataContext;
		const nextState: IState = { board };

		const shouldResetStage = this.props.boards?.length;
		if (shouldResetStage) {
			const newStage = new Api.OpportunitiesBoardStageViewModel(this.props.userSession, board.stages[0]);
			nextState.stage = newStage;
		}

		// @ts-ignore
		this.setState(nextState);
	};

	private onRequestClose = (result?: Api.OpportunityViewModel, canceled?: boolean) => () => {
		EventLogger.logInput('EditOpportunity', 'Close', 'Click');
		if (this.props.parentModal) {
			this.props.parentModal.onRequestClose(result, canceled);
		}
	};

	private onInputRef = (ref: HTMLInputElement) => {
		this.contactInput = ref;
	};
}

const EditOpportunityAsObserver = observer(_EditOpportunity);
export const EditOpportunity = inject(
	EnvironmentKey,
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey,
	ModalChildComponentContextKey
)(EditOpportunityAsObserver);
export const EditOpportunityModal = asModalComponent(EditOpportunity, {
	overlayPresentationStyle: {
		className: 'modal-overlay-no-padding modal-overlay',
		transitionInDuration: 450,
		transitionOutDuration: 450,
	},
	useDefaultHeader: false,
});
