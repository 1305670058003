import { StyleSheet } from 'aphrodite';
import { brandSecondary, prestoPurple, success } from '../../styles/colors';
import { BreakPoints, CssSelectors } from '../../styles/styles';

const cardWidth = 480;
const cardHeight = 500;

export const styleSheet = StyleSheet.create({
	bottomButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		fontSize: 20,
		justifyContent: 'center',
		marginBottom: -66,
		padding: 20,
	},
	tryAIButton: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		gap: 4,
	},
	card: {
		minHeight: cardHeight,
		width: cardWidth,
	},
	cardsContainer: {
		height: cardHeight,
		width: cardWidth,
	},
	container: {
		height: '100%',
		position: 'relative',
	},
	content: {
		height: '100%',
		width: '100%',
	},
	powerUserViewButton: {
		position: 'absolute',
		top: 50,
		right: 30,
		color: `${prestoPurple} !important`,
		background:
			'linear-gradient(225deg, rgba(50,197,255,.1) 0%, rgba(182,32,224,.1) 51%, rgba(247,181,0,.1) 100%), white !important',
		border: '3px solid rgba(182,32,224,.1) !important',
		borderRadius: '30px !important',
	},
	keyboardInputKey: {
		bottom: '10%',
		color: '#CAE0EC',
		fontSize: 14,
		position: 'absolute',
		right: 0,
		transform: 'translate(-50%, -150%)',
		[BreakPoints.tabletWidth]: {
			fontSize: 12,
		},
		[BreakPoints.forMaxWidth(1200)]: {
			right: -112,
		},
		[BreakPoints.forMaxWidth(1000)]: {
			display: 'none',
		},
	},
	keyboardInputKeyDown: {
		borderLeft: `5px solid transparent`,
		borderRight: `5px solid transparent`,
		borderTop: `10px solid ${success}`,
		height: 0,
		width: 0,
	},
	keyboardInputKeyItem: {
		alignItems: 'center',
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	keyboardInputKeyLeft: {
		borderBottom: `5px solid transparent`,
		borderRight: `10px solid ${success}`,
		borderTop: `5px solid transparent`,
		height: 0,
		width: 0,
	},
	keyboardInputKeyRight: {
		borderBottom: `5px solid transparent`,
		borderLeft: `10px solid ${success}`,
		borderTop: `5px solid transparent`,
		height: 0,
		width: 0,
	},
	leftOptionButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		fontSize: 14,
		marginRight: 66,
		textAlign: 'right',
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	leftOptionDeleteIcon: {
		height: 25,
		width: 25,
	},
	placeholder: {
		color: '#fff',
		textAlign: 'center',
	},
	placeholderImage: {
		margin: 'auto auto 30px auto',
	},
	rightOptionButton: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		fontSize: 14,
		marginLeft: 66,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	rightOptionCheck: {
		height: 25,
		width: 25,
	},
	statsBadge: {
		margin: 'auto',
		[BreakPoints.tabletWidth]: {
			height: 'auto',
			width: 100,
		},
		[BreakPoints.tabletCompactWidth]: {
			height: 'auto',
			width: 60,
		},
	},
	statsBadgeContainer: {
		textAlign: 'center',
	},
	statsContainer: {
		left: 30,
		position: 'absolute',
		top: 30,
		...CssSelectors.allChildren(
			{
				marginTop: 20,
			},
			':not(:first-child)'
		),
		[BreakPoints.tabletCompactWidth]: {
			left: '50%',
			transform: 'translate3d(-50%, 0, 0)',
			[BreakPoints.tabletCompactHeight]: {
				display: 'none',
			},
		},
		[BreakPoints.forMaxWidth(1200)]: {
			left: -32,
		},
	},
	title: {
		color: brandSecondary,
		fontSize: 60,
		left: '50%',
		position: 'absolute',
		textAlign: 'center',
		top: 60,
		transform: 'translate(-50%, -50%)',
		zIndex: 1,
		[BreakPoints.tabletCompactHeight]: {
			fontSize: 40,
		},
		[BreakPoints.forMaxWidth(1200)]: {
			fontSize: 40,
		},
		[BreakPoints.tabletCompactWidth]: {
			display: 'none',
		},
	},
	undoDelete: {
		color: '#fff',
		fontSize: 14,
		left: 0,
		maxWidth: 100,
		position: 'absolute',
		top: '50%',
		transform: 'translate(50px, -50%)',
		[BreakPoints.tabletWidth]: {
			maxWidth: 80,
		},
	},
	undoDeleteButton: {
		color: '#56D2FF',
		fontSize: 14,
	},
	undoDeleteTitle: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
	},
});
