import { StyleSheet } from 'aphrodite';
import bg from '../../../assets/focused-view-background.png';
import { background } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	container: {},
	defaultChildStyle: {
		boxSizing: 'border-box',
		height: '100%',
		marginLeft: 30,
		paddingRight: 30,
		position: 'relative',
		width: '100%',
		background,
	},
	focusedHeader: {
		background: `url(${bg}) no-repeat`,
		backgroundSize: '100% auto',
		height: 100,
	},
});
