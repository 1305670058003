import { css, StyleDeclarationValue } from 'aphrodite';
import * as Api from '@ViewModels';
import * as React from 'react';
import { styleSheet } from './styles';
import { useInsurancePolicyFilter } from '../../../../queries';
import { Badge } from '../../dataBoards/Badge';
import { numberToCurrencyStringValue } from '../../../../models/UiUtils';
import moment from 'moment';
import { LoadingSpinner } from '../../LoadingSpinner';

export const CompactPoliciesInfo = ({
	contact,
	rowStyles,
}: {
	contact: Api.IContact;
	rowStyles?: StyleDeclarationValue[];
}) => {
	const policyFilterQuery = useInsurancePolicyFilter({
		enabled: !!contact.id,
		filterRequest: {
			criteria: [
				{
					property: Api.InsurancePolicyFilterProperty.Contact,
					value: contact.id,
				},
			],
		},
	});

	const policies = React.useMemo(() => {
		return policyFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [policyFilterQuery.data]);

	const formatDateOrNull = (dateString?: string) => {
		return dateString ? moment(dateString).format('MM/DD/YYYY') : '-';
	};

	return (
		<div className={css(styleSheet.container)}>
			{policyFilterQuery.isLoading ? <LoadingSpinner /> : null}

			{!policyFilterQuery.isLoading && !policies.length ? (
				<p className={css(styleSheet.emptyText)}>No policies exist for this contact.</p>
			) : null}

			{policies.map(policy => {
				return (
					<div key={`policy-details-${policy.id}`} className={css(styleSheet.row, ...(rowStyles || []))}>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Policy</label>
							{policy.lineOfBusiness ? (
								<Badge text={policy.lineOfBusiness.name} foregroundColor={policy.lineOfBusiness.foregroundColor} />
							) : null}
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Renewal Date</label>
							<span>{formatDateOrNull(policy.renewalDate)}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Effective Date</label>
							<span>{formatDateOrNull(policy.effectiveDate)}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Policy #</label>
							<span>{policy.policyNumber}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Carrier</label>
							<span>{policy.carrier.name}</span>
						</div>
						<div className={css(styleSheet.box)}>
							<label className={css(styleSheet.label)}>Premium</label>
							<span>{policy.premium ? `$${numberToCurrencyStringValue(policy.premium)}` : '-'}</span>
						</div>
					</div>
				);
			})}
		</div>
	);
};
