import { BoardTemplateType, IBoard, Industry, UserSessionContext } from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../../models/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import {
	BoardStageDefinitionViewModel,
	BoardViewModel,
	OpportunitiesBoardViewModel,
} from '../../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../../styles/styles';
import { EditBoardModal } from '../../../boards/EditBoard';
import OpportunitiesPlaceholderImageUrl from './opportunitiesPlaceholder.svg';
import './styles.less';

interface IProps extends IUserSessionComponentProps, IEventLoggingComponentProps {
	className?: string;
	onBoardCreated?(board: BoardViewModel): void;
}

interface IState {
	initialColumns?: BoardStageDefinitionViewModel[];
	showingCreateBoardModal?: boolean;
}

const getInitialColumnTitles = (userSession: UserSessionContext) => {
	// @ts-ignore
	const industry = userSession.account.toJs().additionalInfo.industry;
	switch (industry) {
		case Industry.RealEstate: {
			return ['Leads', 'Appointment Booked', 'Offer Submitted', 'Under Contract', 'Closed'];
		}
		default: {
			return ['Leads', 'Rescheduled', 'Demo Booked', 'Committed', 'Closing', 'Won', 'Lost'];
		}
	}
};

class _OpportunitiesPlaceholder extends React.Component<IProps, IState> {
	public state: IState = {};

	public render() {
		const { className } = this.props;
		const { showingCreateBoardModal, initialColumns } = this.state;
		return (
			<div className={`opportunities-placeholder ${className || ''}`}>
				<div className='opportunities-placeholder-content'>
					<img src={OpportunitiesPlaceholderImageUrl} className='opportunities-placeholder-content' />
					<div className='opportunities-placeholder-content-message'>
						{'Create an opportunities board to keep track\r\nof your deals and forecast revenue.'}
					</div>
					<button
						className={`${css(baseStyleSheet.ctaButton)} opportunities-placeholder-content-create-button`}
						onClick={this.onCreateBoardClicked}
					>
						<span>Create Board</span>
					</button>
				</div>
				<EditBoardModal
					initialColumns={initialColumns}
					modalProps={{
						// @ts-ignore
						isOpen: showingCreateBoardModal,
						onRequestClose: this.onCreateBoardModalRequestClose,
						shouldCloseOnOverlayClick: false,
					}}
					// @ts-ignore
					onCreateBoard={this.onCreateBoard}
				/>
			</div>
		);
	}

	private onCreateBoard = (
		name: string,
		stageDefinitions: BoardStageDefinitionViewModel[]
	): Promise<OpportunitiesBoardViewModel> => {
		const boardModel: IBoard = {
			name,
			stages: (stageDefinitions || []).map(x => ({
				...x.toJs(),
				templateType: BoardTemplateType.Opportunity,
			})),
			template: {
				type: BoardTemplateType.Opportunity,
			},
		};
		// @ts-ignore
		const board = new OpportunitiesBoardViewModel(this.props.userSession);
		const promise = board.save(boardModel);
		if (promise) {
			// @ts-ignore
			this.props.logInput('CreateBoard', 'Click');
			return new Promise((resolve, reject) => {
				promise
					.then(() => {
						resolve(board);
					})
					.catch(reject);
			});
		}

		// @ts-ignore
		return null;
	};

	private onCreateBoardModalRequestClose = (board: BoardViewModel) => {
		this.setState({
			showingCreateBoardModal: false,
		});

		if (!!board && this.props.onBoardCreated) {
			this.props.onBoardCreated(board);
		}
	};

	private onCreateBoardClicked = () => {
		this.setState({
			// @ts-ignore
			initialColumns: getInitialColumnTitles(this.props.userSession).map(x => {
				const column = new BoardStageDefinitionViewModel();
				column.name = x;
				column.enableStageIndicator = x !== 'Lost' && x !== 'Closed';
				return column;
			}),
			showingCreateBoardModal: true,
		});
	};
}

const OpportunitiesPlaceholderWithContext = inject(UserSessionViewModelKey)(_OpportunitiesPlaceholder);
export const OpportunitiesPlaceholder = withEventLogging(
	OpportunitiesPlaceholderWithContext,
	'OpportunitiesPlaceholder'
);
