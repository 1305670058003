import { SvgIcon } from '../SvgIcon';

export const NewDonationsIcon = ({
	width = 42,
	height = 42,
	strokeFill = '#FFFFFF',
}: { width?: number; height?: number; strokeFill?: string }) => {
	return (
		<SvgIcon width={width} height={height} viewBox='0 0 42 42'>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<g>
					<rect fill='#E072BE' x='0' y='0' width='41' height='41' rx='8' />
					<g transform='translate(11.000000, 13.000000)' fill={strokeFill}>
						<path d='M5,2 C3.346,2 2,3.346 2,5 C2,8.363 6.643,12.207 9,13.806 C11.357,12.207 16,8.363 16,5 C16,3.346 14.654,2 13,2 C11.311,2 10,3.613 10,5 C10,5.552 9.552,6 9,6 C8.448,6 8,5.552 8,5 C8,3.613 6.689,2 5,2 M9,16 C8.816,16 8.632,15.949 8.47,15.848 C8.124,15.632 0,10.491 0,5 C0,2.243 2.243,0 5,0 C6.594,0 8.071,0.837 9,2.08 C9.929,0.837 11.406,0 13,0 C15.757,0 18,2.243 18,5 C18,10.491 9.876,15.632 9.53,15.848 C9.368,15.949 9.184,16 9,16' />
					</g>
				</g>
			</g>
		</SvgIcon>
	);
};
