import { StyleSheet } from 'aphrodite';
import { lightOrange, orange } from '../../../aida/styles/colors';
import { titles } from '../../styles/colors';
import { CssSelectors } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'center',
		display: 'flex',
		width: '100%',
	},
	icon: {
		flexBasis: '60%',
	},
	text: { flexBasis: '60%' },
	additionalHelpText: {
		marginTop: 12,
		width: '90%',
		color: titles,
		fontSize: 14,
		border: `1px solid ${orange}`,
		backgroundColor: lightOrange,
		padding: 6,
		marginBottom: -40,
	},
	additionalHelpContainer: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
	},
	container: {
		padding: '0 20px 20px 20px',
		width: 600,
	},
	footer: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 60,
		...CssSelectors.allChildren(
			{
				marginLeft: 20,
			},
			':not(:first-child)'
		),
	},
	message: {
		color: titles,
		fontSize: 14,
		lineHeight: '24px',
	},
	okButton: {},
	rtnCheckbox: {
		color: titles,
		fontSize: 14,
		textAlign: 'left',
	},
	title: {
		color: titles,
		fontSize: 18,
		marginBottom: 20,
	},
});
