import {
	EntityViewModel,
	EventLogger,
	IOperationResultNoValue,
	OpportunitiesBoardViewModel,
	OpportunitiesEntityViewModel,
	OpportunitiesViewModel,
	OpportunityViewModel,
} from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import Waypoint from 'react-waypoint';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { Portal } from '../../../Portal';
import { AddBoardItemButton } from '../../../boards/AddBoardItemButton';
import { EditOpportunityModal } from '../EditOpportunity';
import { OpportunitiesPlaceholder } from '../OpportunitiesPlaceholder';
import { OpportunityCard } from '../OpportunityCard';
import { styleSheet } from './styles';
import { DataboardsIcon } from '../../../svgs/icons/DataboardsIcon';
import { baseStyleSheet } from '../../../../styles/styles';
import { useInfiniteBoardsQuery } from '../../../../../queries';
import { useUserSession } from '../../../../../models/hooks/appStateHooks';

const _EntityOpportunities = ({
	className,
	entity,
	scrollToBottomWaypointPortalId,
	styles,
}: {
	className?: string;
	entity: EntityViewModel;
	scrollToBottomWaypointPortalId?: string;
	styles?: StyleDeclarationValue[];
}) => {
	const userSession = useUserSession();
	const opportunitiesVm = React.useRef(new OpportunitiesViewModel(userSession)).current;
	const opportunitiesEntity = React.useRef(new OpportunitiesEntityViewModel(userSession, entity)).current;
	const [board, setBoard] = React.useState<OpportunitiesBoardViewModel>(null);
	const [opportunityToEdit, setOpportunityToEdit] = React.useState<OpportunityViewModel>(null);
	const [showingAddOpportunityModal, setShowingAddOpportunityModal] = React.useState(false);

	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
	});

	React.useEffect(() => {
		load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const load = () => {
		const promise = opportunitiesVm.getBoards();
		if (promise) {
			promise
				.then(() => {
					const opportunitiesBoard =
						!!opportunitiesVm.boards && opportunitiesVm.boards.length > 0 ? opportunitiesVm.boards[0] : null;
					if (opportunitiesBoard) {
						// get content
						opportunitiesEntity.getArchivedOpportunities();
						opportunitiesEntity.getOpportunities();
						setBoard(opportunitiesBoard);
					}
				})
				.catch((error: IOperationResultNoValue) => {
					EventLogger.logEvent(
						{
							action: 'GetOpportunityBoards-Error',
							category: 'CompanyOpportunities',
						},
						{ ...error }
					);
				});
		}
	};

	const onBoardCreated = (newBoard: OpportunitiesBoardViewModel) => setBoard(newBoard);

	const onAddItemClicked = () => setShowingAddOpportunityModal(true);

	const onEditOpportunityModalRequestClose = () => {
		setShowingAddOpportunityModal(false);
		setOpportunityToEdit(null);
		opportunitiesEntity.resetOpportunities();
		opportunitiesEntity.getOpportunities();
	};

	const onFetchNextBatchOfOpportunities =
		(archived = false) =>
		() => {
			if (!!archived && !opportunitiesEntity.isFetchingArchivedOpportunities) {
				opportunitiesEntity.getArchivedOpportunities();
				return;
			}

			if (!archived && !opportunitiesEntity.isFetchingOpportunities) {
				opportunitiesEntity.getOpportunities();
			}
		};

	const onOpportunityArchived = (opportunity: OpportunityViewModel) => {
		opportunitiesEntity.removeOpportunities([opportunity]);
		opportunitiesEntity.resetArchivedOpportunities();
		opportunitiesEntity.getArchivedOpportunities();
	};

	const onOpportunityDeleted = (opportunity: OpportunityViewModel) => {
		opportunitiesEntity.removeOpportunities([opportunity]);
		opportunitiesEntity.removeArchivedOpportunities([opportunity]);
	};

	const renderBottomWaypoint = () => {
		const waypoint = <Waypoint bottomOffset='-200px' onEnter={onFetchNextBatchOfOpportunities(true)} />;
		if (scrollToBottomWaypointPortalId) {
			return <Portal destination={scrollToBottomWaypointPortalId}>{waypoint}</Portal>;
		}

		return waypoint;
	};

	if (opportunitiesVm.isBusy) {
		return (
			<div className={css(styleSheet.placeholder)}>
				<LoadingSpinner type='small' />
			</div>
		);
	}

	if (!board) {
		// @ts-ignore
		return <OpportunitiesPlaceholder className={css(styleSheet.placeholder)} onBoardCreated={onBoardCreated} />;
	}

	const onClickToEdit = (opportunity: OpportunityViewModel) => {
		setOpportunityToEdit(opportunity);
	};

	const boards = boardsQuery.data?.pages.map(page => page.values).flat() ?? [];
	const opportunities = opportunitiesEntity.opportunities ?? [];

	return (
		<div className={`${css(...(styles || []))} ${className || ''}`}>
			<div className={css(styleSheet.contentSection)}>
				<header className={css(styleSheet.contentHeader)}>
					<figure className={css(styleSheet.boardIconContainer, baseStyleSheet.bgGreen)}>
						<DataboardsIcon height={16} width={21} className={css(styleSheet.opportuntitiesIcon)} />
					</figure>
					<h6 className={css(styleSheet.contentListTitle)}>Current Opportunities</h6>
					<AddBoardItemButton className={css(styleSheet.contentAddButton)} onClick={onAddItemClicked}>
						Add Opportunity
					</AddBoardItemButton>
				</header>
				<div className={css(styleSheet.cardList)}>
					{opportunities.length ? (
						<>
							{opportunities.map(x => {
								return (
									<OpportunityCard
										// @ts-ignore
										initialBoard={board}
										className={css(styleSheet.item)}
										key={x.id}
										onArchived={() => onOpportunityArchived(x)}
										onDeleted={() => onOpportunityDeleted(x)}
										onClickToEdit={() => onClickToEdit(x)}
										opportunity={x}
										showStageIndicator={true}
										editText='Edit'
									/>
								);
							})}
							<Waypoint bottomOffset='-200px' onEnter={onFetchNextBatchOfOpportunities()} />
						</>
					) : null}

					{!opportunitiesEntity.isFetchingOpportunities && !opportunities.length ? (
						<p className={css(styleSheet.emptyText)}>No opportunities exist for this contact.</p>
					) : null}

					{!!opportunitiesEntity.isFetchingOpportunities && <LoadingSpinner type='small' />}
				</div>
			</div>

			{opportunitiesEntity.archivedOpportunities?.length ? (
				<div className={css(styleSheet.contentSection)}>
					<header className={css(styleSheet.contentHeader)}>
						<figure className={css(styleSheet.boardIconContainer, baseStyleSheet.bgGreen)}>
							<DataboardsIcon height={16} width={21} className={css(styleSheet.opportuntitiesIcon)} />
						</figure>
						<h6 className={css(styleSheet.contentListTitle)}>Archived Opportunities</h6>
					</header>
					<div className={css(styleSheet.cardList)}>
						{opportunitiesEntity.archivedOpportunities.map(x => {
							return (
								<OpportunityCard
									// @ts-ignore
									initialBoard={board}
									className={css(styleSheet.item)}
									key={x.id}
									onArchived={() => onOpportunityArchived(x)}
									onDeleted={() => onOpportunityDeleted(x)}
									opportunity={x}
									showStageIndicator={true}
								/>
							);
						})}
						{renderBottomWaypoint()}
						{!!opportunitiesEntity.isFetchingArchivedOpportunities && <LoadingSpinner type='small' />}
					</div>
				</div>
			) : null}

			<EditOpportunityModal
				board={board}
				company={opportunitiesEntity.company}
				modalProps={{
					isOpen: showingAddOpportunityModal || !!opportunityToEdit,
					onRequestClose: onEditOpportunityModalRequestClose,
					shouldCloseOnOverlayClick: false,
				}}
				opportunity={opportunityToEdit}
				primaryContact={opportunitiesEntity.primaryContact}
				boards={boards}
			/>
		</div>
	);
};

const EntityOpportunities = observer(_EntityOpportunities);

// eslint-disable-next-line import/no-default-export
export default EntityOpportunities;
