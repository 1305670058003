import * as Api from '@ViewModels';
import { StyleDeclaration, css } from 'aphrodite';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useEventLogging } from '../../../../models/Logging';
import { getFormattedPhoneNumber } from '../../../../models/UiUtils';
import { useUserSession } from '../../../../models/hooks/appStateHooks';
import { useInput } from '../../../../models/hooks/inputHooks';
import { useLambda } from '../../../../models/hooks/useLambda';
import { useRecentSearches } from '../../../../models/hooks/useRecentSearches';
import { useToggle } from '../../../../models/hooks/useToggle';
import { ContactsAutocompleteResults } from '../../../containers/PeopleV2/shared/ContactsAutocompleteResults';
import { ContactsAutocompleteResultsItem } from '../../../containers/PeopleV2/shared/ContactsAutocompleteResultsItem';
import { ContactsNoRecentSearches } from '../../../containers/PeopleV2/shared/ContactsNoRecentSearches';
import { brandPrimary, destructive, error } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { LoadingSpinner } from '../../LoadingSpinner';
import { TinyPopover } from '../../TinyPopover';
import { XIcon } from '../../svgs/icons/XIcon';
import { useIsGroupTextSupported } from './hooks';
import { styleSheet } from './styles';

export interface ISelectedRecipientPill {
	id: string;
	needsResolved?: boolean;
	value: string;
}
interface IProps {
	anchorStyles?: StyleDeclaration[];
	disableInput?: boolean;
	inputStyles?: StyleDeclaration[];
	leftText?: string;
	onCancel?(): void;
	onRecipientSelected(contact: Api.IContact): void;
	onRemoveRecipient(recipient: ISelectedRecipientPill): void;
	onUnknownNumberSelected?(phoneNumber: string): void;
	pageSize?: number;
	placeholder?: string;
	popoverAnchorStyles?: StyleDeclaration[];
	recentSearchesStorageKey?: string;
	searchButton?: JSX.Element;
	selectedRecipients?: ISelectedRecipientPill[];
	styles?: StyleDeclaration[];
	supportsGroupTexting?: boolean;
}

export const RecipientSearchBase: React.FC<IProps> = ({
	anchorStyles = [],
	disableInput,
	inputStyles = [],
	leftText = null,
	onCancel,
	onRecipientSelected,
	onRemoveRecipient,
	onUnknownNumberSelected,
	pageSize = 3,
	placeholder = 'Search',
	popoverAnchorStyles = [],
	recentSearchesStorageKey = 'RecentSearches',
	searchButton,
	selectedRecipients,
	styles = [],
	supportsGroupTexting,
}) => {
	const userSession = useUserSession();
	const [search, setSearch] = useState('');
	const inputRef = useRef<HTMLInputElement>(null);
	const [isOpen, , setIsOpen] = useToggle(false);
	const [input, manualSetInput, setInput] = useInput('');
	const [parsedInput, setParsedInput] = useState('');
	const [highlight, setHighlight] = useLambda(-1);
	const { logApiError } = useEventLogging('RecipientSearch');
	// @ts-ignore
	const forcedDisabled = useIsGroupTextSupported(selectedRecipients, supportsGroupTexting);

	const regex = useRef(/\d+/g).current;
	const contacts = useRef(
		new Api.ResourceAutoCompleteViewModel(userSession, {
			pageSize,
			type: Api.ResourceAutoCompleteViewModelType.Contact,
		})
	).current;
	const tags = useRef(
		new Api.ResourceAutoCompleteViewModel(userSession, {
			pageSize,
			type: Api.ResourceAutoCompleteViewModelType.AccountTag,
		})
	).current;

	const { recentSearches, addToRecent } = useRecentSearches(recentSearchesStorageKey);
	const [contactsFromRecentSearches, setContactsFromRecentSearches] = React.useState<Api.ContactViewModel[]>();
	const [loading, setLoading] = useState(false);
	const contactFromRecentSearchesRef = useRef<Api.ContactViewModel[]>(contactsFromRecentSearches);
	contactFromRecentSearchesRef.current = contactsFromRecentSearches;
	useEffect(() => {
		setLoading(true);
		// load recent searches
		const getRecentSearches = async () => {
			const result: Api.ContactViewModel[] = [];
			const storedContactModels: Api.IContact[] =
				recentSearches
					// @ts-ignore
					?.filter(x => x.type === 'contact')
					.map(x => x.entity) || [];
			const idsAndIndexes = storedContactModels.map((x, i) => [x.id, i] as const);
			await Promise.all(
				idsAndIndexes.map(idAndIndex => {
					return new Promise(resolve => {
						let vm: Api.ContactViewModel = contactFromRecentSearchesRef.current?.find(x => x.id === idAndIndex[0]);
						if (!vm) {
							const storedContact = storedContactModels[idAndIndex[1]];
							vm = new Api.ContactViewModel(userSession, storedContact);
							vm.load()
								.then(() => {
									if (vm.hasLoaded) {
										// save it back
										addToRecent({
											entity: vm.toJs(),
											type: 'contact',
										});
									}
									result.push(vm);
									resolve(null);
								})
								.catch(err => {
									resolve(null);
									logApiError('Contact-Load', err);
								});
						}
					});
				})
			);
			setContactsFromRecentSearches(result);
			setLoading(false);
		};
		getRecentSearches();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setSearch(input);
		contacts.setSearchQuery(input);
		// @ts-ignore
		setParsedInput(input.match(regex)?.join(''));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [input]);

	const onKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			const contactsMore = contacts.totalCount > contacts.searchResults.length;
			const contactsLength = contacts.searchResults.length + (contactsMore ? 1 : 0);
			const tagsLength = tags.searchResults.length;
			if (event.key === 'Enter') {
				// do nothing if no search has been entered and no item has been selected
				if (search === '' && highlight === -1) {
					return;
				}

				if (highlight > -1) {
					if (search === '') {
						const recent = recentSearches[highlight];
						searchForContact(recent.entity as Api.IContact)();
						return;
					} else {
						searchForContact(contacts.searchResults[highlight] as Api.IContact)();
					}
				}
			} else if (event.key === 'ArrowDown') {
				if (search === '' && highlight < recentSearches.length - 1) {
					setHighlight(highlight + 1);
				} else if (highlight < contactsLength + tagsLength - 1) {
					setHighlight(highlight + 1);
				}
			} else if (event.key === 'ArrowUp') {
				if (highlight > -1) {
					setHighlight(highlight - 1);
				}
			} else {
				// set back to the input
				setHighlight(-1);
				setIsOpen(true)();
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[search, highlight, contacts.searchResults, tags.searchResults]
	);

	const onRemoveRecipientClick = (recipient: ISelectedRecipientPill) => () => {
		onRemoveRecipient(recipient);
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const searchForContact = (contact: Api.IContact) => () => {
		onRecipientSelected(contact);
		addToRecent({
			entity: contact,
			type: 'contact',
		});
		manualSetInput('');
		// @ts-ignore
		inputRef.current.blur();
		setIsOpen(false)();
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const getPhoneToShowAsSecondaryInfo = (phoneNumbers: Api.IPhoneNumber[] = []) => {
		const phoneNumber = Api.VmUtils.getTextMessageCapablePhoneNumbers(phoneNumbers)?.[0] ?? phoneNumbers?.[0];
		return phoneNumber?.metadata?.standard ?? <span style={{ color: destructive }}>No number</span>;
	};

	const onRenderContact = React.useCallback(
		(x: Api.IContact, idx: number) => {
			return (
				<ContactsAutocompleteResultsItem
					onClick={searchForContact(x)}
					highlight={idx === highlight}
					entity={x}
					type='contact'
					key={x.id}
					secondaryInfo={getPhoneToShowAsSecondaryInfo(x?.phoneNumbers)}
					styles={[styleSheet.autoCompleteResultItem]}
				/>
			);
		},
		[highlight, searchForContact, getPhoneToShowAsSecondaryInfo]
	);

	const onSendTextToUnknownNumberClick = (phoneNumber: string) => () => {
		onUnknownNumberSelected?.(phoneNumber);
		// TODO: add number to recent searches?
		manualSetInput('');
		// @ts-ignore
		inputRef.current.blur();
		setIsOpen(false)();
	};

	const renderNoResultsFooterItem = useCallback(() => {
		if (parsedInput?.length === 0) {
			return null;
		}

		if (parsedInput?.length === 10) {
			return (
				<Button
					className={css(styleSheet.sendTextToUnknownNumberCta)}
					label={`Send text to ${getFormattedPhoneNumber(parsedInput)}`}
					onClick={onSendTextToUnknownNumberClick(parsedInput)}
				/>
			);
		}

		return <div className={css(styleSheet.footer)}>Enter a valid 10 digit phone number</div>;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parsedInput]);

	const renderSelectedRecipients = () => {
		if (!selectedRecipients?.length) {
			return null;
		}

		const pills = selectedRecipients.map(r => (
			<div key={r.id} className={css(styleSheet.recipientPill, r.needsResolved && styleSheet.needsResolved)}>
				{r.value}
				<button onClick={onRemoveRecipientClick(r)}>
					<XIcon width={12} height={12} fillColor={r.needsResolved ? error : brandPrimary} />
				</button>
			</div>
		));

		return <div className={css(styleSheet.selectedRecipients)}>{pills}</div>;
	};

	return (
		<div className={css(styleSheet.container, ...styles)}>
			<span className={css(styleSheet.leftText)}>{leftText}</span>
			<TinyPopover
				isOpen={isOpen}
				anchor={
					<div className={css(styleSheet.anchorContainer, ...anchorStyles)}>
						<div
							className={`global-search-container ${css(
								baseStyleSheet.textField,
								styleSheet.inputContainer,
								// @ts-ignore
								selectedRecipients?.length > 0 && styleSheet.inputWithRecipients,
								...inputStyles
							)}`}
						>
							{renderSelectedRecipients()}
							<input
								autoComplete='off'
								disabled={disableInput || forcedDisabled}
								id='global-search'
								onChange={setInput}
								onFocus={setIsOpen(true)}
								onKeyDown={onKeyDown}
								placeholder={forcedDisabled ? undefined : placeholder}
								ref={inputRef}
								type='text'
								value={input}
							/>
							{!!searchButton && searchButton}
						</div>
						{!!onCancel && (
							<button className={css(baseStyleSheet.brandLink, styleSheet.cancelButton)} onClick={onCancel}>
								Cancel
							</button>
						)}
					</div>
				}
				anchorStyles={popoverAnchorStyles}
				onRequestClose={setIsOpen(false)}
				dismissOnOutsideAction={true}
				placement={['bottom', 'top']}
			>
				<div className={css(styleSheet.autocompleteContainer)}>
					{input ? (
						<div>
							{(!parsedInput?.length || contacts.totalCount > 0) && (
								<div className={css(styleSheet.title)}>contacts</div>
							)}
							<ContactsAutocompleteResults
								highlightIndex={highlight}
								items={contacts}
								// @ts-ignore
								noResultsFooterItem={renderNoResultsFooterItem()}
								pageSize={pageSize}
								renderItem={onRenderContact}
								search={input}
								shiftedBy={0}
								type='contact'
							/>
						</div>
					) : (
						<div>
							<div className={css(styleSheet.title)}>recent searches</div>
							<div>
								{loading ? (
									<LoadingSpinner type='small' />
								) : (
									<>
										{contactsFromRecentSearches?.length ? (
											contactsFromRecentSearches.map((x, i) => {
												const contactModel = x.toJs();
												if (!x.hasLoaded) {
													return null;
												}
												return (
													<ContactsAutocompleteResultsItem
														className={css(styleSheet.recentSearch)}
														entity={contactModel}
														highlight={highlight === i}
														isBusy={x.isBusy}
														key={i}
														onClick={searchForContact(contactModel)}
														secondaryInfo={getPhoneToShowAsSecondaryInfo(contactModel.phoneNumbers)}
														styles={[styleSheet.autoCompleteResultItem]}
														type='contact'
													/>
												);
											})
										) : (
											<ContactsNoRecentSearches />
										)}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			</TinyPopover>
		</div>
	);
};

export const RecipientSearch = observer(RecipientSearchBase);
