import { useEventLogging } from '@AppModels/Logging';
import * as Api from '@ViewModels';
import { observer } from 'mobx-react';
import { ILocationState } from '../../../../models';
import { useErrorMessages, useFullscreenModal, useUserSession } from '../../../../models/hooks/appStateHooks';
import { invalidateDashboardResourceSelector } from '../../../../queries';
import * as AppViewModels from '../../../../viewmodels/AppViewModels';
import {
	IDashboardEmails,
	IUpcomingKeyDateDashboardCard,
	getCustomCardDisplayName,
} from '../../../../viewmodels/AppViewModels';
import { titles } from '../../../styles/colors';
import { GhostHeading } from '../../GhostHeading';
import { GhostImage } from '../../GhostImage';
import { GhostCard } from '../../GhostsCard';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { NewDonationsIcon } from '../../svgs/icons/DonorIcons/NewDonationsIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { DashboardCard } from '../DashboardCard';
import { EmailsToSendButton } from './EmailsToSendButton';
import { AutomationIcon, BirthdayIcon, CommercialIcon, NewClientIcon, NewLeadIcon, RenewalIcon } from './icons';
import { styleSheet } from './styles';
import { conditionalTextFormatted } from './utils';

export const CARD_COLORS = {
	blue: 'rgba(0, 170, 232, 0.5)',
	green: 'rgba(137, 201, 71,0.5)',
	green2: 'rgb(186,216,153)',
	orange: 'rgba(248, 145, 67, 0.5)',
	violet: 'rgba(199, 93, 166, 0.5)',
	violet2: 'rgba(189, 168, 235, 0.5)',
	yellow: 'rgba(245, 223, 134, 0.5)',
	pink: 'rgb(224, 114, 190)',
};

interface IProps {
	launchCampaignApproval: (campaignId: string) => void;
	data: IDashboardEmails;
	isLoading: boolean;
}

const getMonthTotalsFromDashboardCards = (keyDateDashCards: Api.IUpcomingKeyDateDashboardCard[]) => {
	return keyDateDashCards.map(dashCard => {
		return {
			month: dashCard.contacts[0].keyFact.keyDate.month,
			total: dashCard.total,
		};
	});
};

function _EmailsToSend({ launchCampaignApproval, data, isLoading }: IProps) {
	const userSession = useUserSession();
	const errorMessages = useErrorMessages();
	const fullscreenModal = useFullscreenModal();
	const { logApiError } = useEventLogging('EmailsToSend');
	const mSingleEmailGuideViewModel: AppViewModels.ComposeEmailToWithGuideViewModel =
		new AppViewModels.ComposeEmailToWithGuideViewModel(userSession);

	if (isLoading || data == null) {
		return (
			<GhostCard stylesDeclarations={[styleSheet.card]}>
				<>
					<div style={{ alignItems: 'center', display: 'flex', marginBottom: 10 }}>
						<GhostImage style={{ marginRight: 8 }} />{' '}
						<div>
							<GhostHeading style={{ marginBottom: 5 }} />
							<GhostHeading style={{ height: 8 }} />
						</div>
					</div>
					<div style={{ alignItems: 'center', display: 'flex', marginBottom: 10 }}>
						<GhostImage style={{ marginRight: 8 }} /> <GhostHeading />
					</div>
					<div style={{ alignItems: 'center', display: 'flex', marginBottom: 10 }}>
						<GhostImage style={{ marginRight: 8 }} /> <GhostHeading />
					</div>
				</>
			</GhostCard>
		);
	}

	function countAutomations(automationsOnHold: AppViewModels.IAutomationOnHold[]) {
		return automationsOnHold.reduce((acc, i) => acc + i.count, 0);
	}

	const pendingAutomationsCount = data.automationsOnHold ? countAutomations(data.automationsOnHold) : 0;
	const newLeadAutomationsCount = data.newLeadAutomationsOnHold ? countAutomations(data.newLeadAutomationsOnHold) : 0;

	const clientAutomationOnHold = (clientType: Api.NewClientType) => (x: AppViewModels.IAutomationOnHold) => {
		const trigger: Api.INewClientAutomationTrigger = x.template.publishedTrigger;
		return trigger?.clientType?.indexOf(clientType) >= 0;
	};
	const newCommercialClientAutomationsOnHold = (data.newClientAutomationsOnHold ?? []).filter(
		clientAutomationOnHold(Api.NewClientType.Commercial)
	);
	const newPersonalClientAutomationsOnHold = (data.newClientAutomationsOnHold ?? []).filter(
		clientAutomationOnHold(Api.NewClientType.Personal)
	);
	const newClientAutomationsOnHold = (data.newClientAutomationsOnHold ?? []).filter(
		clientAutomationOnHold(Api.NewClientType.Any)
	);
	const newDonorAutomationsOnHold = data.newDonorAutomationsOnHold ?? [];

	const newCommercialClientAutomationsCount = countAutomations(newCommercialClientAutomationsOnHold);
	const newPersonalClientAutomationsCount = countAutomations(newPersonalClientAutomationsOnHold);
	const newClientAutomationsCount = countAutomations(newClientAutomationsOnHold);
	const newDonorAutomationsCount = countAutomations(newDonorAutomationsOnHold);
	const CUSTOM_CARDS = data
		? // FOLLOWUP: Resolve
			// @ts-ignore
			Object.keys(data).filter((key: keyof IDashboardEmails) => key.startsWith('custom') && data[key])
		: [];

	const SOME_BUTTONS_SHOWING =
		data.campaignsOnHold.length > 0 ||
		(data.upcomingBirthdays?.total ?? 0) > 0 ||
		data.upcomingReviews.length > 0 ||
		data.upcomingRenewals.length > 0 ||
		newLeadAutomationsCount > 0 ||
		newClientAutomationsCount > 0 ||
		newDonorAutomationsCount > 0 ||
		newPersonalClientAutomationsCount > 0 ||
		newCommercialClientAutomationsCount > 0 ||
		(data.upcomingAnniversaries?.total ?? 0) > 0 ||
		data?.upcoming65thBirthdays?.length > 0 ||
		data?.upcoming72ndBirthdays?.length > 0 ||
		data?.upcoming73rdBirthdays?.length > 0 ||
		pendingAutomationsCount > 0 ||
		data.upcomingTurningsXX?.length > 0 ||
		CUSTOM_CARDS.length > 0;

	const onOnHoldAutomationsCardClicked = (
		automations: AppViewModels.IAutomationOnHold[],
		resourceId: Api.ResourceSelectorId,
		context?: any
	) => {
		const automationsOnHold = AppViewModels.AutomationsOnHoldViewModel.instanceForResource(
			userSession,
			automations,
			resourceId,
			context
		);
		const locationState: ILocationState<AppViewModels.AutomationsOnHoldViewModel, any> = {
			viewModel: automationsOnHold,
		};
		fullscreenModal.history.push({
			pathname: `/automationsonhold/${resourceId}`,
			state: locationState,
		});
	};

	const onAutomationsClick = () => {
		const automationsOnHold = new AppViewModels.AutomationsOnHoldViewModel(
			userSession,
			data.automationsOnHold,
			userSession.canSendOnBehalf
		);
		const locationState: ILocationState<AppViewModels.AutomationsOnHoldViewModel, any> = {
			viewModel: automationsOnHold,
		};
		fullscreenModal.history.push({
			pathname: `/automationsonhold/${Api.ResourceSelectorId.AutomationsOnHold}`,
			state: locationState,
		});
	};

	const onCampaignsClick = () => {
		const pendingCampaigns = data.campaignsOnHold;
		if (pendingCampaigns.length === 1) {
			launchCampaignApproval?.(pendingCampaigns[0].id);
			return;
		}
		fullscreenModal.history.push({
			pathname: '/email/bulk-send/pending',
		});
	};

	const onSendAcknowledgeableKeyDateEmail = async (upcomingKeyDate: Api.IUpcomingKeyDateDashboardCard) => {
		const item = upcomingKeyDate.contacts[0];

		const onFinish = (reload: boolean) => {
			if (reload) {
				invalidateDashboardResourceSelector();
			}
		};

		if (!!item?.keyFact && item?.keyFact?.keyDate?.kind) {
			if (upcomingKeyDate.contacts.length === 0 && !userSession.account.features?.automation?.enabled) {
				const contact = new Api.ContactViewModel(userSession, {
					id: item.contact.id,
				});
				await contact.load();

				await mSingleEmailGuideViewModel.openForKeyDateSend(contact, upcomingKeyDate, onFinish);
			} else {
				const promise = Api.TemplatesViewModel.getResourceSelectorTemplate(userSession, upcomingKeyDate.type);
				promise
					.then(template => {
						const locationState: ILocationState<Api.ITemplate, Api.IUpcomingKeyDateDashboardCard> = {
							model: upcomingKeyDate,
							viewModel: template.value,
						};
						fullscreenModal?.history.push({
							pathname: '/email/campaigns/create/from-resource-selector',
							state: locationState,
						});
					})
					.catch((error: Api.IOperationResultNoValue) => {
						if (error.systemCode === 404) {
							const locationState: ILocationState<Api.ITemplate, Api.IUpcomingKeyDateDashboardCard> = {
								model: upcomingKeyDate,
							};
							fullscreenModal?.history.push({
								pathname: '/email/campaigns/create/from-resource-selector',
								state: locationState,
							});
							return;
						}
						logApiError('LoadKeyDateTemplate-Error', error);
						errorMessages.pushApiError(error);
					});
			}
		}
	};

	if (!SOME_BUTTONS_SHOWING) {
		return null;
	}

	return (
		<DashboardCard styleDeclaration={styleSheet.card}>
			{data.campaignsOnHold?.length > 0 ? (
				<EmailsToSendButton
					key='campaign'
					color={CARD_COLORS.blue}
					counter={data.campaignsOnHold?.length}
					icon={<EmailIcon fillColor='#0D0F11' />}
					heading='Pending Campaigns'
					subHeading='See our suggestions'
					onClick={() => onCampaignsClick()}
				/>
			) : null}
			{(data.upcomingBirthdays?.total ?? 0) > 0 ? (
				<EmailsToSendButton
					key='birthdays'
					color={CARD_COLORS.violet}
					counter={data?.upcomingBirthdays?.total}
					icon={BirthdayIcon}
					heading='Upcoming Birthdays'
					onClick={() => onSendAcknowledgeableKeyDateEmail?.(data.upcomingBirthdays)}
				/>
			) : null}
			{data.upcomingRenewals?.length > 0 ? (
				<EmailsToSendButton
					key='renewal'
					color={CARD_COLORS.orange}
					counter={data.upcomingRenewals.length}
					icon={RenewalIcon}
					heading='Renewal'
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcomingRenewals[index])}
					shouldUpdateHeading={true}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcomingRenewals)}
				/>
			) : null}
			{data.upcomingReviews.length > 0 ? (
				<EmailsToSendButton
					key='review'
					color={CARD_COLORS.orange}
					counter={data.upcomingReviews.length}
					icon={RenewalIcon}
					heading='Review'
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcomingReviews[index])}
					shouldUpdateHeading={true}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcomingReviews)}
				/>
			) : null}
			{newLeadAutomationsCount > 0 ? (
				<EmailsToSendButton
					key='lead'
					color={CARD_COLORS.green}
					counter={data.newLeadAutomationsOnHold?.length ?? 0}
					icon={NewLeadIcon}
					heading={conditionalTextFormatted(`New Lead`, pendingAutomationsCount > 1)}
					onClick={() =>
						onOnHoldAutomationsCardClicked(
							data.newLeadAutomationsOnHold,
							Api.ResourceSelectorId.NewLeadAutomationsOnHold
						)
					}
				/>
			) : null}
			{newClientAutomationsCount > 0 ? (
				<EmailsToSendButton
					key='client'
					color={CARD_COLORS.blue}
					counter={newClientAutomationsCount || 0}
					icon={NewClientIcon}
					heading={conditionalTextFormatted(`New Client`, pendingAutomationsCount > 1)}
					onClick={() =>
						onOnHoldAutomationsCardClicked(
							newClientAutomationsOnHold,
							Api.ResourceSelectorId.NewClientAutomationsOnHold,
							Api.NewClientType.Any
						)
					}
				/>
			) : null}

			{newPersonalClientAutomationsCount > 0 ? (
				<EmailsToSendButton
					key='personal'
					color={CARD_COLORS.blue}
					counter={newCommercialClientAutomationsCount || 0}
					icon={NewClientIcon}
					heading={conditionalTextFormatted(`New ${Api.NewClientType.Personal} Client`, pendingAutomationsCount > 1)}
					onClick={() =>
						onOnHoldAutomationsCardClicked(
							newPersonalClientAutomationsOnHold,
							Api.ResourceSelectorId.NewClientAutomationsOnHold,
							Api.NewClientType.Personal
						)
					}
				/>
			) : null}

			{newCommercialClientAutomationsCount > 0 ? (
				<EmailsToSendButton
					key='commercial'
					color={CARD_COLORS.blue}
					counter={newCommercialClientAutomationsCount || 0}
					icon={CommercialIcon}
					heading='New Comm. Clients'
					onClick={() =>
						onOnHoldAutomationsCardClicked(
							newCommercialClientAutomationsOnHold,
							Api.ResourceSelectorId.NewClientAutomationsOnHold,
							Api.NewClientType.Commercial
						)
					}
				/>
			) : null}

			{newDonorAutomationsCount ? (
				<EmailsToSendButton
					key='donor'
					color={CARD_COLORS.pink}
					counter={newDonorAutomationsCount || 0}
					icon={<NewDonationsIcon strokeFill={titles} />}
					heading={conditionalTextFormatted(`New Donor`, pendingAutomationsCount > 1)}
					onClick={() =>
						onOnHoldAutomationsCardClicked(newDonorAutomationsOnHold, Api.ResourceSelectorId.NewDonorAutomationsOnHold)
					}
				/>
			) : null}

			{(data.upcomingAnniversaries?.total ?? 0) > 0 ? (
				<EmailsToSendButton
					key='anniversary'
					color={CARD_COLORS.blue}
					counter={data.upcomingAnniversaries?.total}
					icon={NewClientIcon}
					heading={conditionalTextFormatted(`House Anniversar`, data.upcomingAnniversaries?.total > 1, 'ies', 'y')}
					onClick={() => onSendAcknowledgeableKeyDateEmail?.(data.upcomingAnniversaries)}
				/>
			) : null}
			{data.upcoming65thBirthdays.length > 0 && (
				<EmailsToSendButton
					key='65'
					color={CARD_COLORS.yellow}
					counter={data.upcoming65thBirthdays?.length || 0}
					icon={BirthdayIcon}
					heading='Turning 65'
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcoming65thBirthdays[index])}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcoming65thBirthdays)}
				/>
			)}
			{data.upcoming72ndBirthdays.length > 0 && (
				<EmailsToSendButton
					key='72'
					color={CARD_COLORS.yellow}
					counter={data.upcoming72ndBirthdays?.length || 0}
					icon={BirthdayIcon}
					heading='Turning 72'
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcoming72ndBirthdays[index])}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcoming72ndBirthdays)}
				/>
			)}
			{data.upcoming73rdBirthdays.length > 0 && (
				<EmailsToSendButton
					key='73'
					color={CARD_COLORS.yellow}
					counter={data.upcoming73rdBirthdays?.length || 0}
					icon={BirthdayIcon}
					heading='Turning 73'
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcoming73rdBirthdays[index])}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcoming73rdBirthdays)}
				/>
			)}
			{pendingAutomationsCount > 0 ? (
				<EmailsToSendButton
					key='pending'
					color={CARD_COLORS.blue}
					counter={data.automationsOnHold?.length ?? 0}
					icon={AutomationIcon}
					heading='Pending Automations'
					onClick={() => onAutomationsClick()}
				/>
			) : null}
			{data?.upcomingTurningsXX?.length > 0 ? (
				<EmailsToSendButton
					key='turningXX'
					color={CARD_COLORS.violet2}
					counter={data?.upcomingTurningsXX?.length || 0}
					icon={BirthdayIcon}
					heading={`Turning ${userSession?.account?.preferences?.resourceSelectorSettings?.TurningXX?.age}`}
					onClick={index => onSendAcknowledgeableKeyDateEmail(data.upcomingTurningsXX[index])}
					monthTotals={getMonthTotalsFromDashboardCards(data.upcomingTurningsXX)}
				/>
			) : null}
			{/* FOLLOWUP: Resolve */}
			{/* @ts-ignore*/}
			{CUSTOM_CARDS.map((key: keyof IDashboardEmails) => {
				const cardData = data[key] as IUpcomingKeyDateDashboardCard;
				const cardType = (key.charAt(0).toUpperCase() + key.slice(1)) as Api.ResourceSelectorId;
				if (cardData) {
					return (
						<EmailsToSendButton
							key={key}
							color={CARD_COLORS.green2}
							counter={cardData.total}
							icon={<CalendarDateIcon fillColor='#0D0F11' width={18} height={18} />}
							heading={getCustomCardDisplayName(cardType, userSession.account.toJs())}
							onClick={() => onSendAcknowledgeableKeyDateEmail(cardData)}
						/>
					);
				}
				return null;
			})}
		</DashboardCard>
	);
}

export const EmailsToSend = observer(_EmailsToSend);
