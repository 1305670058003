import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { observable } from 'mobx';
import { Observer, inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { useContactAIClassificationGeneration } from '../../../queries/TaggingGame';
import {
	ClassifyContactViewModel,
	ContactViewModel,
	IOperationResultNoValue,
	ITaggingGameActivities,
	IUserStats,
	IUserWithStats,
	UnclassifiedContactsViewModel,
	UserWithStatsViewModel,
} from '../../../viewmodels/AppViewModels';
import { Button } from '../../components/Button';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { ContactTaggingGameCard } from '../../components/contacts/ContactTaggingGameCard';
import { ContactsAddTagModal } from '../../components/contacts/ContactsAddTagModal';
import { WebFullscreenModal } from '../../components/fullscreen/WebFullscreenModal';
import {
	IInteractiveCategorizationCallbacks,
	IInteractiveCategorizationItemInfo,
	ISortedInteractiveCategorizationItemInfo,
	InteractiveCategorization,
} from '../../components/layout/InteractiveCategorization';
import { UserStatsCounter } from '../../components/svgs/UserStatsCounter';
import { AIStarsIcon } from '../../components/svgs/icons/AIStars';
import { CheckmarkIcon } from '../../components/svgs/icons/CheckmarkIcon';
import { SvgIcon } from '../../components/svgs/icons/SvgIcon';
import { TrashIcon } from '../../components/svgs/icons/TrashIcon';
import { success } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { aiModeAllowedIndustriesArray } from './ContactTaggingGamePowerUser';
import PlaceholderImageUrl from './contactTaggingGamePlaceholder.png';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		RouteComponentProps<any>,
		IUserSessionComponentProps,
		IErrorMessageComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	excludeContactIds?: string[];
	loadedAll?: boolean;
	loading?: boolean;
	onAfterAddTagModalClosed?(): void;
	prefetchedUnclassifiedContacts?: ClassifyContactViewModel[];
	sortInfo?: ISortedInteractiveCategorizationItemInfo<ClassifyContactViewModel>[];
	stats?: IUserStats;
	totalContacts?: number;
	unclassifiedContactForAddTagModal?: ClassifyContactViewModel;
	unclassifiedContacts?: ClassifyContactViewModel[];
}

class _ContactTaggingGame extends React.Component<IProps, IState> {
	@observable.ref
	private mPendingDelete: ISortedInteractiveCategorizationItemInfo<ClassifyContactViewModel>;
	@observable.ref private mPrefetchPromise: Promise<ClassifyContactViewModel[]>;
	private mSwipeCallbacks: IInteractiveCategorizationCallbacks;
	private mUnclassifiedContacts: UnclassifiedContactsViewModel;
	private mUser: UserWithStatsViewModel;

	private static PageSize = 10;

	constructor(props: IProps) {
		super(props);
		this.mUnclassifiedContacts = new UnclassifiedContactsViewModel(props.userSession);
		this.mUser = new UserWithStatsViewModel(props.userSession, props.userSession.user as IUserWithStats);
		this.state = {
			excludeContactIds: [],
			prefetchedUnclassifiedContacts: [],
			sortInfo: [],
			totalContacts: null,
			unclassifiedContacts: [],
		};
	}

	public componentDidMount() {
		this.loadContacts();
		this.getOrUpdateStats();
	}

	public componentWillUnmount() {
		if (this.mPendingDelete) {
			this.deleteContact(this.mPendingDelete.item?.contact);
		}
	}

	public render() {
		const { className, styles } = this.props;
		const { unclassifiedContacts, loading, unclassifiedContactForAddTagModal } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} contact-tagging-game ${className || ''}`}>
				<div className={css(styleSheet.title)}>Tagging Game</div>
				<PreloadAIClassifications
					excludedContactIds={this.state.excludeContactIds}
					enabled={!!unclassifiedContacts.length}
				/>
				<InteractiveCategorization
					items={unclassifiedContacts}
					itemsContainerStyles={[styleSheet.cardsContainer]}
					loading={!!loading}
					onInnerRef={this.onActionCallbacksRef}
					onKeyForItem={this.onKeyForItem}
					onRenderBottomOption={this.onRenderBottomOption}
					onRenderEmptyPlaceholder={this.onRenderEmptyPlaceholder}
					onRenderItem={this.onRenderContactCard}
					onRenderItemsLeftAccessory={this.onRenderItemsLeftAccessory}
					onRenderItemsRightAccessory={this.onRenderItemsRightAccessory}
					onRenderLeftOption={this.onRenderLeftOption}
					onRenderLoading={this.onRenderLoading}
					onRenderRightOption={this.onRenderRightOption}
					onRequestMore={this.onRequestMore}
					onSwipeEnd={this.onSwipeEnd}
					styles={[styleSheet.content]}
				/>
				<ContactsAddTagModal
					modalProps={{
						isOpen: !!unclassifiedContactForAddTagModal,
						onAfterClose: this.onContactsAddTagModalAfterClose,
						onRequestClose: this.onAddTagModalRequestClose,
					}}
					onAddTagButtonClicked={this.onContactsAddTagModalAddTagButtonClicked}
				/>
				<WebFullscreenModal />
			</div>
		);
	}

	private onRenderItemsRightAccessory = () => {
		const shouldShowAIMode = () =>
			this.props.userSession.account.preferences.storeSubjectInCommunicationHistory &&
			aiModeAllowedIndustriesArray.some(
				allowedIndustry => allowedIndustry === this.props.userSession.account.additionalInfo.industry
			);
		return (
			<>
				{this.props.userSession.account.preferences.storeSubjectInCommunicationHistory ? (
					<NavLink to='/people/tagging-game/power-user'>
						<Button
							className={css(styleSheet.powerUserViewButton)}
							label={
								<div className={css(styleSheet.tryAIButton)}>
									{shouldShowAIMode() ? (
										<>
											<AIStarsIcon height={25} width={25} />
											Try AI Mode
										</>
									) : (
										'Power User Mode'
									)}
								</div>
							}
						/>
					</NavLink>
				) : null}
				<div className={css(styleSheet.keyboardInputKey)}>
					<div>Keyboard controls:</div>
					<ul>
						<li className={css(styleSheet.keyboardInputKeyItem)}>
							<div className={css(styleSheet.keyboardInputKeyLeft)} />
							<span>Delete</span>
						</li>
						<li className={css(styleSheet.keyboardInputKeyItem)}>
							<div className={css(styleSheet.keyboardInputKeyRight)} />
							<span>Keep</span>
						</li>
						<li className={css(styleSheet.keyboardInputKeyItem)}>
							<div className={css(styleSheet.keyboardInputKeyDown)} />
							<span>Ask me later</span>
						</li>
					</ul>
				</div>
			</>
		);
	};

	private onRenderItemsLeftAccessory = (callbacks?: IInteractiveCategorizationCallbacks) => {
		return (
			<Observer>
				{() => {
					const stats = this.onRenderStats();
					if (!this.mPendingDelete?.item) {
						return stats;
					}
					return (
						<>
							{stats}
							<div className={css(styleSheet.undoDelete)}>
								<div className={css(styleSheet.undoDeleteTitle)}>
									<span className={css(baseStyleSheet.truncateText)}>
										{this.mPendingDelete.item.contact.name}
										&nbsp;
									</span>
									<span>Deleted</span>
								</div>
								<button className={css(styleSheet.undoDeleteButton)} onClick={this.onUndoPendingDelete(callbacks)}>
									<span>Undo</span>
								</button>
							</div>
						</>
					);
				}}
			</Observer>
		);
	};

	private onRenderStats = () => {
		const { totalContacts, stats } = this.state;
		if (!stats) {
			return null;
		}
		const count = Math.min(totalContacts || 0);

		return (
			<div className={css(styleSheet.statsContainer)}>
				<div className={css(styleSheet.statsBadgeContainer)}>
					<UserStatsCounter className={css(styleSheet.statsBadge)} count={stats.taggingGamePoints} />
				</div>
				<SvgIcon height={39} opacity={count ? 1 : 0} width={157}>
					<g fill='none' fillRule='evenodd'>
						<path
							stroke='#00AAE8'
							strokeLinecap='square'
							strokeWidth='.5'
							d='M.5.5L156.5.5M101.5 10.5L144 10.5M8 10.5L50.5 10.5M18 20.5L134 20.5'
						/>
						<text fill='#00AAE8' fontFamily='Sora, Open Sans' fontSize='14' alignmentBaseline='baseline'>
							<tspan x={74.5 - count.toString().length * 4.5} y='15'>
								{count}
							</tspan>
						</text>
						<text fill='#00AAE8' fontFamily='Sora, Open Sans' fontSize='11' letterSpacing='.786'>
							<tspan x='30' y='38'>
								CONTACTS LEFT
							</tspan>
						</text>
					</g>
				</SvgIcon>
			</div>
		);
	};

	private onRenderEmptyPlaceholder = () => {
		return (
			<div className={css(baseStyleSheet.absoluteCenter, styleSheet.placeholder)}>
				<img src={PlaceholderImageUrl} className={css(styleSheet.placeholderImage)} />
				<div>You&apos;ve tagged all of your contacts!</div>
			</div>
		);
	};

	private onRenderLoading = () => {
		return <LoadingSpinner className={css(baseStyleSheet.absoluteCenter)} type='loginSecondary' />;
	};

	private onRenderBottomOption = (callbacks?: IInteractiveCategorizationCallbacks) => {
		return (
			<button
				className={css(styleSheet.bottomButton)}
				onClick={this.onDirectionSelected('bottom', callbacks?.swipeBottom)}
			>
				<span>Ask me later</span>
			</button>
		);
	};

	private onRenderRightOption = (callbacks?: IInteractiveCategorizationCallbacks) => {
		return (
			<button
				className={css(styleSheet.rightOptionButton)}
				onClick={this.onDirectionSelected('right', callbacks?.swipeRight)}
			>
				<CheckmarkIcon className={css(styleSheet.rightOptionCheck)} fillColor={success} type='bold' />
				<span>Drag right to keep</span>
			</button>
		);
	};

	private onRenderLeftOption = (callbacks?: IInteractiveCategorizationCallbacks) => {
		return (
			<button
				className={css(styleSheet.leftOptionButton)}
				onClick={this.onDirectionSelected('left', callbacks?.swipeLeft)}
			>
				<span>Drag left to delete</span>
				<TrashIcon className={css(styleSheet.leftOptionDeleteIcon)} fillColor='#F89143' />
			</button>
		);
	};

	private onRenderContactCard = (info: IInteractiveCategorizationItemInfo<ClassifyContactViewModel>) => {
		return (
			<ContactTaggingGameCard
				key={this.onKeyForItem(info)}
				onAddTagButtonClicked={this.onAddTagButtonClicked(info.item)}
				onDoneButtonClicked={this.onCardDoneButtonClicked(info.item)}
				styles={[styleSheet.card]}
				unclassifiedContact={info.item}
			/>
		);
	};

	private onCardDoneButtonClicked =
		(classifyContact: ClassifyContactViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			e.preventDefault();
			e.stopPropagation();
			this.swipeRight(classifyContact, false);
		};

	private onContactsAddTagModalAddTagButtonClicked = (
		tagValue: string,
		e: React.MouseEvent<HTMLElement, MouseEvent>
	) => {
		e.preventDefault();
		e.stopPropagation();
		const unclassifiedContact = this.state.unclassifiedContactForAddTagModal;
		const promise = unclassifiedContact.contact.addTags([tagValue]);
		if (promise) {
			const { logApiError, logEvent, errorMessages } = this.props;
			logEvent('CustomContactTagAdded', {
				contactId: unclassifiedContact.contact.id,
				tagLength: tagValue.length,
			});
			promise
				.then(() => {
					this.setState({
						unclassifiedContactForAddTagModal: null,
					});
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError('CustomContactTagAdded-Error', error);
					errorMessages.pushApiError(error);
					this.setState({
						unclassifiedContactForAddTagModal: null,
					});
				});
		}
	};

	private onContactsAddTagModalAfterClose = () => {
		const { onAfterAddTagModalClosed } = this.state;
		if (onAfterAddTagModalClosed) {
			onAfterAddTagModalClosed();
			this.setState({
				onAfterAddTagModalClosed: null,
			});
		}
	};

	private onAddTagModalRequestClose = () => {
		this.setState({
			unclassifiedContactForAddTagModal: null,
		});
	};

	private onAddTagButtonClicked =
		(unclassifiedContact: ClassifyContactViewModel) => (e: React.MouseEvent<HTMLElement>) => {
			e.preventDefault();
			e.stopPropagation();

			this.setState({
				unclassifiedContactForAddTagModal: unclassifiedContact,
			});
		};

	private onActionCallbacksRef = (ref?: IInteractiveCategorizationCallbacks) => {
		this.mSwipeCallbacks = ref;
	};

	private onUndoPendingDelete = (callbacks?: IInteractiveCategorizationCallbacks) => () => {
		this.mPendingDelete = null;
		callbacks?.undoLastSwipe();
		this.getOrUpdateStats({
			contactsDeleted: -1,
		});
		this.setState({
			totalContacts: this.state.totalContacts + 1,
		});
	};

	private onSwipeEnd = (sortedItem: ISortedInteractiveCategorizationItemInfo<ClassifyContactViewModel>) => {
		const { sortInfo, totalContacts } = this.state;
		const { logApiError, logEvent } = this.props;
		const nextState: IState = {
			sortInfo: [...(sortInfo || []), sortedItem],
		};

		if (this.mPendingDelete) {
			this.deleteContact(this.mPendingDelete.item?.contact);
			this.mPendingDelete = null;
		}

		if (sortedItem.axis === 'x') {
			if (sortedItem.direction > 0) {
				// swipe right
				let tagsAdded = sortedItem.item.contact?.tags?.length || 0;
				const eventContext = { id: sortedItem.item.contact.id };
				logEvent('ContactSwipeRight', eventContext);
				if ((sortedItem.item?.contact?.tags || []).indexOf('Keep in Touch') < 0) {
					logEvent('AddKeepInTouchTag', eventContext);
					tagsAdded++;
					sortedItem.item.contact
						.updateTags([...(sortedItem.item?.contact?.tags || []), 'Keep in Touch'])
						?.catch((error: IOperationResultNoValue) => {
							logApiError('AddKeepInTouchTag-Error', error);
							this.setState({
								loading: false,
							});
						});
				}
				this.getOrUpdateStats({
					contactsKept: 1,
					tagsAdded,
				});
			} else if (sortedItem.direction < 0) {
				// swipe left
				this.mPendingDelete = sortedItem;
				this.getOrUpdateStats({
					contactsDeleted: 1,
				});
			}
		}

		if (totalContacts > 0) {
			nextState.totalContacts = totalContacts - 1;
		}
		this.setState(nextState);
	};

	private swipeRight = (classifyContact: ClassifyContactViewModel, addKeepInTouchTag = true) => {
		const { unclassifiedContacts } = this.state;

		if (!!addKeepInTouchTag && (classifyContact.contact?.tags || []).indexOf('Keep in Touch') < 0) {
			const { logApiError } = this.props;
			classifyContact.contact
				?.updateTags([...(classifyContact.contact?.tags || []), 'Keep in Touch'])
				?.catch((error: IOperationResultNoValue) => {
					logApiError('AddKeepInTouchTag-Error', error);
				});
		}
		this.mSwipeCallbacks?.swipeRight(unclassifiedContacts.indexOf(classifyContact));
	};

	private onKeyForItem = (info: IInteractiveCategorizationItemInfo<ClassifyContactViewModel>) => {
		return `${info.item.id}-${info.index}`;
	};

	private onDirectionSelected = (_: 'right' | 'left' | 'bottom', callback?: () => Promise<void>) => () => {
		if (callback) {
			callback();
		}
	};

	private onRequestMore = () => {
		this.loadContacts(true);
	};

	private prefetchContacts = () => {
		const { logApiError, logEvent, errorMessages } = this.props;
		const { excludeContactIds, loadedAll, prefetchedUnclassifiedContacts } = this.state;
		if (!this.mPrefetchPromise && !this.mUnclassifiedContacts.isBusy) {
			this.mUnclassifiedContacts.resetUnclassifiedContacts();

			if (loadedAll) {
				logEvent('LoadedAllContacts');
				return;
			}

			this.mPrefetchPromise = new Promise<ClassifyContactViewModel[]>(resolve => {
				const pageSize = 2 * _ContactTaggingGame.PageSize;
				const promise = this.mUnclassifiedContacts.fetchUnclassifiedContacts({ excludeContactIds: [] }, pageSize);
				if (promise) {
					const eventName = 'PrefetchContacts';
					logEvent(eventName);
					promise
						.then(() => {
							this.mPrefetchPromise = null;
							const unclassified = [...this.mUnclassifiedContacts.unclassifiedContacts.toArray()];
							this.setState({
								excludeContactIds: [...excludeContactIds, ...unclassified.map(x => x.id)],
								loadedAll: unclassified.length < pageSize,
								prefetchedUnclassifiedContacts: [...prefetchedUnclassifiedContacts, ...unclassified],
							});
							resolve(unclassified);
						})
						.catch((error: IOperationResultNoValue) => {
							this.mPrefetchPromise = null;
							logApiError(`${eventName}-Error`, error);
							errorMessages.pushApiError(error);
							resolve(undefined);
						});
				} else {
					this.mPrefetchPromise = null;
					resolve(undefined);
				}
			});
		}
	};

	private getOrUpdateStats = (activities?: ITaggingGameActivities) => {
		const { logEvent, logApiError } = this.props;
		if (!activities) {
			const promise = this.mUser.loadUserStats();
			if (promise) {
				logEvent('GetUserStats');
				promise
					.then(stats => {
						this.setState({
							stats,
						});
					})
					.catch((error: IOperationResultNoValue) => {
						logApiError('GetUserStats-Error', error);
					});
			}
		} else {
			const promise = this.mUser.updateTaggingGameActivities(activities);
			if (promise) {
				logEvent('UpdateTaggingGameActivities', { activities });
				promise
					.then(stats => {
						this.setState({
							stats,
						});
					})
					.catch((error: IOperationResultNoValue) => {
						this.mPrefetchPromise = null;
						logApiError('UpdateTaggingGameActivities-Error', error);
					});
			}
		}
	};

	private loadContacts = (more = false) => {
		const { logApiError, logEvent, errorMessages } = this.props;
		const {
			excludeContactIds,
			loadedAll,
			unclassifiedContacts,
			prefetchedUnclassifiedContacts,
			loading,
			totalContacts,
		} = this.state;
		if (loading) {
			return;
		}

		this.mUnclassifiedContacts.resetUnclassifiedContacts();
		if (more) {
			if (this.mPendingDelete) {
				this.deleteContact(this.mPendingDelete.item.contact);
				this.mPendingDelete = null;
			}

			const getFromPrefetch = (
				resolve?: (value: ClassifyContactViewModel[] | PromiseLike<ClassifyContactViewModel[]>) => void
			) => {
				this.setState(
					currentState => {
						const unclassified = currentState.prefetchedUnclassifiedContacts.slice(0, _ContactTaggingGame.PageSize);
						const prefetched = currentState.prefetchedUnclassifiedContacts.slice(unclassified.length);
						return {
							loading: false,
							prefetchedUnclassifiedContacts: prefetched,
							unclassifiedContacts: unclassified,
						};
					},
					() => {
						this.prefetchContacts();
						if (resolve) {
							resolve(undefined);
						}
					}
				);
			};

			if (prefetchedUnclassifiedContacts.length > 0) {
				this.setState({
					loading: true,
				});
				return new Promise<ClassifyContactViewModel[]>(resolve => {
					setTimeout(() => {
						getFromPrefetch(resolve);
					}, 100);
				});
			} else if (this.mPrefetchPromise) {
				return this.mPrefetchPromise.then(() => {
					getFromPrefetch();
				});
			}

			if (loadedAll) {
				logEvent('LoadedAllContacts');
				if (unclassifiedContacts.length > 0) {
					this.setState({
						unclassifiedContacts: [],
					});
				}
				return Promise.resolve<ClassifyContactViewModel[]>([]);
			}
		}

		const promise = this.mUnclassifiedContacts.fetchUnclassifiedContacts(
			{ excludeContactIds },
			_ContactTaggingGame.PageSize
		);
		if (promise) {
			const eventName = `ContactsLoad${more ? 'More' : ''}`;
			logEvent(eventName);
			this.setState({
				loading: true,
			});
			promise
				.then(() => {
					const unclassified = [...this.mUnclassifiedContacts.unclassifiedContacts.toArray()];
					const nextState: IState = {
						excludeContactIds: [...excludeContactIds, ...unclassified.map(x => x.id)],
						loadedAll: unclassified.length < _ContactTaggingGame.PageSize,
						loading: false,
						unclassifiedContacts: unclassified,
					};
					if (!totalContacts) {
						nextState.totalContacts = this.mUnclassifiedContacts.totalCount;
					}
					this.setState(nextState, this.prefetchContacts);
				})
				.catch((error: IOperationResultNoValue) => {
					logApiError(`${eventName}-Error`, error);
					errorMessages.pushApiError(error);
					this.setState({
						loading: false,
					});
				});
		}
		return promise;
	};

	private deleteContact = (contact: ContactViewModel) => {
		const { logApiError, logEvent } = this.props;
		const promise = contact?.delete();
		if (promise) {
			logEvent('DeleteContact', { id: contact.id });
			promise.catch((error: IOperationResultNoValue) => {
				logApiError('DeleteContact-Error', error);
			});
		}
	};
}

const ContactTaggingGameAsObserver = observer(_ContactTaggingGame);
const ContactTaggingGameWithContext = inject(
	UserSessionViewModelKey,
	ErrorMessagesViewModelKey
)(ContactTaggingGameAsObserver);
export const ContactTaggingGame = withEventLogging(ContactTaggingGameWithContext, 'ContactTaggingGame');

const PreloadAIClassifications: React.FC<{ excludedContactIds: string[]; enabled: boolean }> = ({
	excludedContactIds,
	enabled,
}) => {
	useContactAIClassificationGeneration({ contactIdsToExclude: excludedContactIds, enabled });
	return null;
};
