import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import { styleSheet } from './styles';
import EntityOpportunities from '../opportunities/EntityOpportunities';
import { EntityDonations } from '../donations/EntityDonations';
import { EntityPolicies } from '../policies/EntityPolicies';
import { useUserSession } from '../../../../models/hooks/appStateHooks';

export const EntityDataBoards = observer(({ entityViewModel }: { entityViewModel: Api.EntityViewModel }) => {
	const userSession = useUserSession();
	const entity = entityViewModel.toJs();

	const { policiesEnabled, donationsEnabled, opportunitiesEnabled } = userSession.account.features?.boards ?? {};

	return (
		<div className={css(styleSheet.container)}>
			{opportunitiesEnabled ? <EntityOpportunities entity={entityViewModel} /> : null}

			{donationsEnabled ? <EntityDonations entity={entity} /> : null}

			{policiesEnabled ? <EntityPolicies entity={entity} /> : null}
		</div>
	);
});
