import { AutomationTemplateViewModel, IOperationResultNoValue, TemplateType, VmUtils } from '@ViewModels';
import { css } from 'aphrodite';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ILocationState } from '../../../../../models';
import {
	ErrorMessagesViewModelKey,
	IErrorMessageComponentProps,
	IToasterComponentProps,
	IUserSessionComponentProps,
	ToasterViewModelKey,
	UserSessionViewModelKey,
} from '../../../../../models/AppState';
import { Topics } from '../../../../../models/LocalNotificationTopics';
import { EventLogger, IEventLoggingComponentProps, withEventLogging } from '../../../../../models/Logging';
import { getDefaultDateStringValue, numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { invalidateArchivedOpportunities } from '../../../../../queries';
import { BoardViewModel, IOpportunity, OpportunityViewModel } from '../../../../../viewmodels/AppViewModels';
import { DeprecatedMoreMenu } from '../../../DeprecatedMoreMenu';
import {
	INotificationServiceComponentProps,
	withNotificationService,
} from '../../../LocalNotificationObserver/WithNotificationService';
import { IMoreMenuItem } from '../../../MoreMenu';
import { AutomationPopoverPrompt } from '../../../automation/AutomationPopoverPrompt';
import { StageIndicator } from '../../../boards/StageIndicator';
import { FullscreenModalNavLink } from '../../../fullscreen/FullscreenModalNavLink';
import { styleSheet } from './styles';
import './styles.less';

interface IProps
	extends RouteComponentProps<any>,
		IErrorMessageComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IToasterComponentProps,
		INotificationServiceComponentProps<IOpportunity> {
	initialBoard: BoardViewModel;
	className?: string;
	onArchived?(): void;
	onClickToEdit?: (opportunity: OpportunityViewModel) => void;
	onDeleted?(): void;
	opportunity: OpportunityViewModel;
	showStageIndicator?: boolean;
	editText?: string;
}

interface IState {
	hovering?: boolean;
	moreMenuItems?: IMoreMenuItem[];
	redirection?: {
		pathname: string;
		state: ILocationState<OpportunityViewModel, any>;
	};
	showingAutomationInfo?: boolean;
}

class _OpportunityCard extends React.Component<IProps, IState> {
	private MoreMenuOptions: IMoreMenuItem[] = [
		{
			name: 'Permanently Delete',
			representedObject: 'delete',
		},
	];
	public state: IState = {
		showingAutomationInfo: false,
	};
	@observable.ref private automationTemplate: AutomationTemplateViewModel;
	constructor(props: IProps) {
		super(props);
	}

	public UNSAFE_componentWillMount() {
		const { editText, opportunity, userSession, initialBoard, onClickToEdit } = this.props;

		this.MoreMenuOptions = [
			onClickToEdit
				? {
						name: editText ? editText : !opportunity.isArchived ? 'Move to Column' : 'Restore',
						representedObject: !opportunity.isArchived ? 'moveToColumn' : 'restoreColumn',
					}
				: null,
			...this.MoreMenuOptions,
		];

		if (opportunity?.pendingAutomationId) {
			// load automation template
			this.automationTemplate = new AutomationTemplateViewModel(userSession, {
				id: opportunity?.pendingAutomationId,
				templateType: TemplateType.Automation,
			});
			this.automationTemplate
				?.load()
				?.then(() => {
					this.toggleAutomationPopover(true)();
				})
				?.catch((err: IOperationResultNoValue) => {
					const { errorMessages, logApiError } = this.props;
					errorMessages.pushApiError(err);
					logApiError('OpportunityCardLoadAutomationTemplate', err);
				});
		}

		let moreMenuItems = [...this.MoreMenuOptions];
		if (!opportunity.isArchived) {
			moreMenuItems = [
				{
					name: 'Archive',
					representedObject: 'archive',
				},
				...this.MoreMenuOptions,
			];
		}

		const redirectLocationState: ILocationState<OpportunityViewModel, any> = {
			viewModel: opportunity,
		};
		this.setState({
			moreMenuItems,
			redirection: {
				pathname: `/dataBoards/opportunities/${initialBoard.id}/${opportunity.id}`,
				state: redirectLocationState,
			},
		});
	}

	public render() {
		const { className, opportunity, showStageIndicator, userSession } = this.props;
		const { hovering, moreMenuItems, redirection, showingAutomationInfo } = this.state;
		const name = opportunity.name || '';
		return (
			<div
				className={`opportunity-card ${opportunity.isBusy ? 'opportunity-card-busy' : ''} ${className || ''}`}
				onMouseEnter={this.toggleMouseHover(true)}
				onMouseLeave={this.toggleMouseHover(false)}
			>
				{!!hovering && !opportunity.isBusy && (
					<DeprecatedMoreMenu
						menuButtonClassName='opportunity-card-more-menu'
						menuItems={moreMenuItems}
						onMenuItemClicked={this.onMoreMenuItemSelected}
					/>
				)}
				<FullscreenModalNavLink
					className='opportunity-card-title truncate-text'
					title={`Name: ${name}${
						opportunity.company ? '\nCompany:' + opportunity.company.name : ''
					}\nPrimary Contact: ${opportunity.primaryContact.name}`}
					to={redirection}
				>
					<span className='truncate-text opportunity-card-title-content'>{name}</span>
				</FullscreenModalNavLink>
				{!!showStageIndicator && !!opportunity.stage && (
					<div className='opportunity-card-stage-indicator-container'>
						<StageIndicator
							className='opportunity-card-stage-indicator'
							currentIndex={opportunity.stage.indexInBoard}
							hideFutureStages={true}
							total={opportunity.stage.boardStageCount}
							type='compact'
						/>
						<span className='opportunity-card-stage-indicator-name'>{opportunity.stage.name}</span>
					</div>
				)}
				<div className='opportunity-card-meta'>
					<div>
						<span className='opportunity-card-field-label'>{`Close date: `}</span>
						<span className='opportunity-card-field-text'>
							{opportunity.closeDate ? getDefaultDateStringValue(opportunity.closeDate) : '-'}
						</span>
					</div>
					<div>
						<span className='opportunity-card-field-label'>{`Owner: `}</span>
						<span className='opportunity-card-field-text'>{opportunity.owner.name}</span>
					</div>
				</div>
				<div className='opportunity-card-amount'>{`$${numberToCurrencyStringValue(opportunity.dealSize)}`}</div>
				{userSession?.account?.features?.automation?.enabled && (
					<AutomationPopoverPrompt
						anchor={<div className={css(styleSheet.automationAnchor)} />}
						automationTemplate={this.automationTemplate}
						isOpen={!!showingAutomationInfo}
						onCloseClicked={this.ignoreAutomation}
						onCtaClicked={this.startAutomation}
						preferredPlacement='below'
					/>
				)}
			</div>
		);
	}

	private startAutomation = () => {
		const { opportunity, toaster } = this.props;
		this.toggleAutomationPopover(false)();
		this.automationTemplate
			?.createAutomationForContact(opportunity?.primaryContact, true)
			?.then(() => {
				toaster.push({
					message: `Automation "${this.automationTemplate.name}" started for ${VmUtils.getDisplayName(
						opportunity?.primaryContact
					)}`,
					type: 'successMessage',
				});
				opportunity?.setPendingAutomationId(null);
			})
			?.catch((err: IOperationResultNoValue) => {
				toaster.push({
					message: `Unable to start automation "${this.automationTemplate.name}" for ${VmUtils.getDisplayName(
						opportunity?.primaryContact
					)}`,
					type: 'errorMessage',
				});
				const { logApiError } = this.props;
				logApiError('OpportunityCardStartAutomation', err);
			});
	};

	private ignoreAutomation = () => {
		const { opportunity } = this.props;
		opportunity?.setPendingAutomationId(null);
		this.toggleAutomationPopover(false)();
	};

	private toggleAutomationPopover = (isOpen: boolean) => () => {
		this.setState({ showingAutomationInfo: isOpen });
	};

	private onMoreMenuItemSelected = (menuItem: IMoreMenuItem<string>) => {
		if (menuItem.representedObject === 'delete') {
			const { opportunity, onDeleted } = this.props;

			if (opportunity.isArchived && onDeleted) {
				onDeleted();
				return;
			}

			const promise = this.props.opportunity.stage.deleteItem(this.props.opportunity);
			if (promise) {
				EventLogger.logInput('OpportunityCard', 'Delete', 'Click');
				promise
					.then(() => {
						if (onDeleted) {
							onDeleted();
						}
					})
					.catch((error: IOperationResultNoValue) => {
						if (!!error && !!error.systemCode && error.systemCode === 404) {
							// eat this error
							if (onDeleted) {
								onDeleted();
							}
							return;
						}
						EventLogger.logEvent(
							{
								action: 'Delete-Error',
								category: 'OpportunityCard',
							},
							{ ...error }
						);
						this.props.errorMessages.push({
							messages: [error.systemMessage],
						});
					});
			}
			return;
		}
		if (menuItem.representedObject === 'archive') {
			const { onArchived, postNotification } = this.props;
			const promise = this.props.opportunity.stage.archiveItem(this.props.opportunity);
			if (promise) {
				EventLogger.logInput('OpportunityCard', 'Archive', 'Click');
				promise
					.then(() => {
						if (onArchived) {
							onArchived();
						}
						postNotification({
							info: this.props.opportunity.toJs(),
							topic: Topics.ARCHIVE_OPPORTUNITY,
						});
						invalidateArchivedOpportunities();
					})
					.catch((error: IOperationResultNoValue) => {
						if (!!error && !!error.systemCode && error.systemCode === 404) {
							// eat this error
							if (onArchived) {
								onArchived();
							}
							return;
						}
						EventLogger.logEvent(
							{
								action: 'Archive-Error',
								category: 'OpportunityCard',
							},
							{ ...error }
						);
						this.props.errorMessages.push({
							messages: [error.systemMessage],
						});
					});
			}
			return;
		}
		if (menuItem.representedObject === 'moveToColumn' || menuItem.representedObject === 'restoreColumn') {
			const { onClickToEdit, opportunity } = this.props;
			onClickToEdit(opportunity);
		}
	};

	private toggleMouseHover = (hovering: boolean) => () => {
		this.setState({
			hovering,
		});
	};
}

const OpportunityCardAsObserver = observer(_OpportunityCard);
const OpportunityCardWithInjection = inject(
	ErrorMessagesViewModelKey,
	UserSessionViewModelKey,
	ToasterViewModelKey
)(OpportunityCardAsObserver);
const OpportunityCartWithLogging = withEventLogging(withNotificationService(OpportunityCardWithInjection));
export const OpportunityCard = withRouter(OpportunityCartWithLogging);
