import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { css } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useBoardAdvancedReportingUrlQuery, useInfiniteBoardsQuery } from '../../../queries';
import { LoadingSpinner } from '../LoadingSpinner';
import { MultiContainerHeader } from '../MultiContainerHeader';
import { styleSheet } from './styles';

const iframeContainerId = 'quicksightAdvancedOpportunityReportingDashboard';

export const AdvancedOpportunityReporting = () => {
	const userSession = useUserSession();
	const boardsQuery = useInfiniteBoardsQuery({
		type: 'Opportunity',
	});
	const urlQuery = useBoardAdvancedReportingUrlQuery({
		boardId: boardsQuery.data?.pages?.[0]?.values?.[0]?.id,
		cacheTime: 0,
		enabled: boardsQuery.data?.pages?.[0]?.values?.[0]?.id != null,
	});
	React.useEffect(() => {
		if (urlQuery.data) {
			const parameters = {
				AccountID: userSession.account.id,
			};
			embedDashboard({
				container: document.getElementById(iframeContainerId),
				locale: 'en-US',
				parameters,
				url: urlQuery.data,
			});
		}
	}, [urlQuery.data, userSession]);
	return (
		<>
			<MultiContainerHeader fullscreenHeader='Advanced Reporting' />
			{urlQuery.data == null ? <LoadingSpinner type='large' /> : null}
			{urlQuery.data != null ? <div id={iframeContainerId} className={css(styleSheet.iframeContainer)} /> : null}
		</>
	);
};
