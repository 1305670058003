import { StyleSheet } from 'aphrodite';
import { header, titles } from '../../styles/colors';
import { CssSelectors, Layouts } from '../../styles/styles';

export const styleSheet = StyleSheet.create({
	billingIcon: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
	},
	buttonContainer: {
		...Layouts.horizontalStack(10),
	},
	container: {},
	formFieldLabel: {
		color: header,
		display: 'inline-block',
		fontSize: '0.875rem',
		width: 200,
	},
	formFieldValue: {
		color: titles,
		fontSize: '0.875rem',
	},
	root: {
		padding: '1.25rem 0 7.5rem 0',
		...CssSelectors.allChildren(
			{
				marginTop: '1.25rem',
			},
			':not(:first-child)'
		),
	},
});
