import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import * as React from 'react';
import { ContactSearch } from '../../../../extViewmodels/sdk/models/ContactSearch';
import { getDisplayName } from '../../../../models/UiUtils';
import { useContactSearchQuery, useContactSuggestedTagsQuery } from '../../../../queries';
import { PeopleCollapsibleSection } from '../../../containers/PeopleV2/PeopleContainer/PeopleCollapsibleSection';
import { brandPrimaryHover, navigation } from '../../../styles/colors';
import { bs } from '../../../styles/styles';
import { Checkbox } from '../../Checkbox';
import { GlobalSearch } from '../../GlobalSearch';
import { RadioButton } from '../../RadioButton';
import { XIcon } from '../../svgs/icons/XIcon';
import { SearchIcon } from '../../svgs/icons/SearchIcon';
import { styleSheet } from './styles';

const MaxItemsToShowByDefault = 5;

type Actions =
	| { type: 'setSelectedContactSearch'; payload: Api.ContactSearch }
	| { type: 'selectTag'; payload: Api.AccountTag }
	| { type: 'toggleTag'; payload: string }
	| { type: 'setVisibleTags'; payload: Api.AccountTag[] }
	| { type: 'addSelectedContact'; payload: Api.IContact }
	| { type: 'removeSelectedContact'; payload: string }
	| { type: 'clearSelectedContacts' };

const reducer = (state: State, action: Actions): State => {
	switch (action.type) {
		case 'setSelectedContactSearch': {
			return {
				...state,
				selectedContactSearch: action.payload,
				selectedTagIds: [],
			};
		}
		case 'toggleTag': {
			const tagId = action.payload;
			const selectedTagIds = state.selectedTagIds.includes(tagId)
				? state.selectedTagIds.filter(id => id !== tagId)
				: [...state.selectedTagIds, tagId];
			return {
				...state,
				selectedContactSearch: null,
				selectedTagIds,
			};
		}
		case 'selectTag': {
			const accountTag = action.payload;
			const selectedTagIds = !state.selectedTagIds.includes(accountTag.id)
				? [...state.selectedTagIds, accountTag.id]
				: state.selectedTagIds;
			const visibleTags = !state.visibleTags.find(tag => tag.id === accountTag.id)
				? [accountTag, ...state.visibleTags]
				: state.visibleTags;
			return {
				...state,
				selectedContactSearch: null,
				selectedTagIds,
				visibleTags,
			};
		}
		case 'setVisibleTags': {
			return {
				...state,
				visibleTags: action.payload,
				selectedTagIds: [],
			};
		}
		case 'addSelectedContact': {
			const contact = action.payload;
			const selectedContacts = !state.selectedContacts.find(c => c.id === contact.id)
				? state.selectedContacts.concat([contact])
				: state.selectedContacts;
			return {
				...state,
				selectedContacts,
			};
		}
		case 'removeSelectedContact': {
			return {
				...state,
				selectedContacts: state.selectedContacts.filter(c => c.id !== action.payload),
			};
		}
		case 'clearSelectedContacts': {
			return {
				...state,
				selectedContacts: [],
			};
		}
		default:
			throw new Error('Invalid action type');
	}
};

type State = {
	selectedContactSearch: Api.ContactSearch | null;
	selectedTagIds: string[];
	selectedContacts: Api.IContact[];
	visibleTags: Api.AccountTag[];
};

export type CampaignRecipientsSearchResult = {
	selectedContacts: Api.IContact[];
	selectedTags: Api.AccountTag[];
	selectedContactSearch: Api.ContactSearch | null;
};

export function CampaignRecipientsSearch({
	impersonationContext,
	initialSelectedContacts = [],
	onSubmit,
}: {
	impersonationContext?: Api.IImpersonationContext;
	initialSelectedContacts?: Api.IContact[];
	onSubmit: ({ selectedContacts, selectedTags, selectedContactSearch }: CampaignRecipientsSearchResult) => void;
}) {
	const suggestedTagsQuery = useContactSuggestedTagsQuery({
		addKeepInTouchTag: false,
		pageSize: 6,
		impersonationContext,
	});
	const searchesQuery = useContactSearchQuery({ impersonationContext });
	const [state, dispatch] = React.useReducer(reducer, {
		selectedContactSearch: null,
		selectedTagIds: [],
		selectedContacts: initialSelectedContacts,
		visibleTags: suggestedTagsQuery.data ?? [],
	});
	const [prevSuggestedTags, setPrevSuggestedTags] = React.useState(suggestedTagsQuery.data);
	if (prevSuggestedTags == null && prevSuggestedTags !== suggestedTagsQuery.data) {
		setPrevSuggestedTags(suggestedTagsQuery.data);
		dispatch({ type: 'setVisibleTags', payload: suggestedTagsQuery.data });
	}
	const onTagClick = (tag: Api.AccountTag) => {
		dispatch({ type: 'toggleTag', payload: tag.id });
	};
	const handleTagSelected = (tag: Api.IAccountTag) => {
		dispatch({ type: 'selectTag', payload: tag as Api.AccountTag });
	};
	const handleContactSelected = (contact: Api.IContact) => {
		dispatch({ type: 'addSelectedContact', payload: contact });
	};
	const handleSavedSearchClick = (search: ContactSearch) => {
		dispatch({ type: 'setSelectedContactSearch', payload: search });
	};
	const handleRemoveContact = (contact: Api.IContact) => {
		dispatch({ type: 'removeSelectedContact', payload: contact.id! });
	};
	const handleSubmit = () => {
		const selectedTags: Api.AccountTag[] = [];
		for (const tagId of state.selectedTagIds) {
			const tag = state.visibleTags.find(t => t.id === tagId);
			if (tag) {
				selectedTags.push(tag);
			}
		}
		onSubmit({
			selectedContacts: state.selectedContacts,
			selectedTags,
			selectedContactSearch: state.selectedContactSearch,
		});
	};
	return (
		<div className={css(bs.hFull, bs.overflowAuto, bs.relative)}>
			<div className={css(bs.hFull, bs.overflowAuto)}>
				<div className={css(bs.textTitles, bs.textLg, bs.h8, bs.mb6)}>Select Recipients to Send:</div>
				<div className={css(bs.pb5)}>
					<GlobalSearch onContactSelected={handleContactSelected} onTagSelected={handleTagSelected} />
				</div>
				{state.visibleTags.length > 0 ? (
					<div className={css(bs.pb5)}>
						<div className={css(bs.mb2)}>Popular Tags</div>
						<div className={css(bs.overflowHidden)} style={{ transition: 'height .25s ease-in-out' }}>
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={state.visibleTags.map(tag => {
									const isSelected = state.selectedTagIds.includes(tag.id);
									return (
										<div className={css(bs.flex, styleSheet.itemsContainer)} key={`crs-tag-${tag.id}`}>
											<Checkbox
												checked={isSelected}
												id={`crs-tag-checkbox-${tag.id}`}
												onChange={() => onTagClick(tag)}
											/>
											<div className={css(styleSheet.contentContainer)} onClick={() => onTagClick(tag)}>
												<span
													className={css(
														styleSheet.pill,
														...(isSelected ? [bs.bgBrandPrimary, bs.textWhite] : [bs.bgTagBackground])
													)}
												>
													{tag.tag}
												</span>
											</div>
										</div>
									);
								})}
								itemHeightPx={31}
								showLoader={false}
							/>
						</div>
					</div>
				) : null}
				{!impersonationContext?.account ? (
					<div className={css(bs.pb5)}>
						<div className={css(bs.mb2)}>Saved Searches</div>
						{searchesQuery.status === 'success' ? (
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={
									searchesQuery.data?.map(search => {
										const isSelected =
											state.selectedContactSearch != null && state.selectedContactSearch.id === search.id!;
										return (
											<div className={css(styleSheet.itemsContainer)} key={search.id!}>
												<RadioButton
													checked={isSelected}
													id={`saved-searches-${search.id}`}
													name='saved-searches'
													onClick={() => handleSavedSearchClick(search)}
													readOnly={true}
												>
													<div className={css(styleSheet.contentContainer)}>
														<span
															className={css(
																styleSheet.pill,
																...(isSelected ? [bs.bgBrandPrimary, bs.textWhite] : [bs.bgTagBackground])
															)}
														>
															<SearchIcon fillColor={isSelected ? '#fff' : brandPrimaryHover} />
															{search.name}
														</span>
													</div>
												</RadioButton>
											</div>
										);
									}) ?? []
								}
								itemHeightPx={31}
								showLoader={searchesQuery.status !== 'success'}
							/>
						) : null}
					</div>
				) : null}
				<div className={css(bs.pb5)}>
					<div className={css(bs.mb2)}>Contacts</div>
					<div>
						{state.selectedContacts.length === 0 ? (
							<div className={css(bs.textXs, bs.textGray400)}>
								You can use the search to add individual contacts to your campaign.
							</div>
						) : (
							<PeopleCollapsibleSection
								defaultCountToShow={MaxItemsToShowByDefault}
								items={state.selectedContacts.map(contact => (
									<div
										className={css(bs.flex, bs.itemsCenter, styleSheet.itemsContainer)}
										key={`csr-contact-${contact.id}`}
									>
										<button
											className={css(bs.bgTransparent, bs.border0, bs.mr2, bs.outlineNone, bs.pt1)}
											onClick={() => handleRemoveContact(contact)}
										>
											<XIcon height={12} width={12} fillColor={navigation} />
										</button>
										<div className={css(bs.flex, bs.gap2, bs.itemsCenter)}>
											<span>{getDisplayName(contact)}</span>
											{contact.jobTitle ? <span className={css(bs.textGray400)}>{contact.jobTitle}</span> : null}
										</div>
									</div>
								))}
								itemHeightPx={28}
							/>
						)}
					</div>
				</div>
				<button
					className={css(bs.wFull, bs.ctaButton)}
					disabled={
						!state.selectedContacts.length && !state.selectedTagIds.length && state.selectedContactSearch == null
					}
					onClick={handleSubmit}
				>
					Next: Preview Recipients
				</button>
			</div>
		</div>
	);
}
