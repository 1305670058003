import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { StyleDeclarationValue, css } from 'aphrodite';
import { useEffect } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useAutomationTemplateIframeUrl } from '../../../queries';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { MultiContainerHeader } from '../MultiContainerHeader';
import { styleSheet } from './styles';
interface IProps extends RouteComponentProps<any> {
	className?: string;
	styles?: StyleDeclarationValue[];
}
const embeddingContainerId = 'quicksightAdvancedReportingDashboard';

export const AdvancedReporting = ({ className, styles }: IProps) => {
	const userSession = useUserSession();
	const params = useParams<{ automationId: string }>();
	const { data, isLoading } = useAutomationTemplateIframeUrl({
		enabled: params.automationId != null,
		templateId: params.automationId,
	});

	useEffect(() => {
		if (data) {
			const parameters = {
				AccountID: userSession.account.id,
				AutomationTemplateID: params.automationId,
			};
			embedDashboard({
				container: document.getElementById(embeddingContainerId),
				locale: 'en-US',
				parameters,
				url: `${data}`,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	if (isLoading) {
		return (
			<div className={css(styleSheet.container)}>
				<LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} />
			</div>
		);
	}

	return (
		<div className={`${className ?? ''}`}>
			<MultiContainerHeader fullscreenHeader='Advanced Reporting' />
			<div
				id={embeddingContainerId}
				className={`${css(styleSheet.container, ...(styles || []))} quicksight-advanced-reporting-dashboard ${
					className || ''
				}`}
			/>
		</div>
	);
};
