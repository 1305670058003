import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { styleSheet } from './styles';
import { styleSheet as dbStyleSheet } from '../../../../containers/dataBoards/DataBoardsHome/styles';
import { baseStyleSheet } from '../../../../styles/styles';
import { PolicyIcon } from '../../../svgs/icons/PolicyIcon';
import { AddBoardItemButton } from '../../../boards/AddBoardItemButton';
import { CompactPoliciesInfo } from '../../../contacts/CompactPoliciesInfo';
import { EditPolicyModal } from '../../../../containers/dataBoards/PoliciesBoard/EditPolicyModal/';

export const EntityPolicies = ({
	entity,
}: {
	entity: Api.IEntity;
}) => {
	const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
	const [refreshToken, setRefreshToken] = React.useState(new Date().getTime());

	const addItemClicked = () => {
		setIsAddModalOpen(true);
	};

	const refreshPolicies = () => {
		setRefreshToken(new Date().getTime());
		setIsAddModalOpen(false);
	};

	const newPolicy = {
		contact: entity,
	};

	return (
		<div key={refreshToken}>
			<div className={css(styleSheet.contentSection)}>
				<header className={css(styleSheet.contentHeader)}>
					<figure className={css(dbStyleSheet.boardIconContainer, baseStyleSheet.bgBrightBlue)}>
						<PolicyIcon className={css(styleSheet.policiesIcon)} />
					</figure>
					<h6 className={css(styleSheet.contentListTitle)}>Policies</h6>
					<AddBoardItemButton className={css(styleSheet.contentAddButton)} onClick={addItemClicked}>
						Add Policy
					</AddBoardItemButton>
				</header>
				<CompactPoliciesInfo contact={entity} />
			</div>

			{isAddModalOpen ? (
				<EditPolicyModal
					initialPolicy={newPolicy as Api.IInsurancePolicy}
					isOpen={isAddModalOpen}
					setIsOpen={setIsAddModalOpen}
					onSave={refreshPolicies}
					onCancel={refreshPolicies}
				/>
			) : null}
		</div>
	);
};
