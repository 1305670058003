import { css } from 'aphrodite';
import * as React from 'react';
import registrationError from '../../../assets/registrationError.png';
import registrationSuccess from '../../../assets/registrationSuccess.png';
import { baseStyleSheet, bs } from '../../../styles/styles';
import { styleSheet as mainStyleSheet } from '../styles';
import { styleSheet } from './styles';

const RegistrationInfoLayout = ({ children, imageSrc }: { imageSrc: string; children: React.ReactNode }) => {
	return (
		<div className={css(mainStyleSheet.innerContainer)}>
			<figure className={css(styleSheet.headerImage)}>
				<img src={imageSrc} alt='Texting Campaign Registration' />
			</figure>
			<header className={css(styleSheet.containerHeader)}>{children}</header>
		</div>
	);
};

export const RegistrationInfoSuccess = () => {
	return (
		<RegistrationInfoLayout imageSrc={registrationSuccess}>
			<h2>Thank You!</h2>
			<p>Thank you for submitting the form. We will get your brand registered.</p>
		</RegistrationInfoLayout>
	);
};

export const RegistrationInfoAccepted = ({ onStartTextingClicked }: { onStartTextingClicked: () => void }) => {
	return (
		<RegistrationInfoLayout imageSrc={registrationSuccess}>
			<h2>Thank You!</h2>
			<p>Your campaign registration is now accepted.</p>
			<button onClick={onStartTextingClicked} className={css(baseStyleSheet.ctaButton)}>
				Start Texting
			</button>
		</RegistrationInfoLayout>
	);
};

export const RegistrationInfoPending = () => {
	return (
		<RegistrationInfoLayout imageSrc={registrationSuccess}>
			<h2>Thank You!</h2>
			<p>Your campaign registration is now pending. Please check back on the status.</p>
		</RegistrationInfoLayout>
	);
};

export const TextingNotEnabled = () => (
	<RegistrationInfoLayout imageSrc={registrationError}>
		<h2>Sorry!</h2>
		<p className={css(bs.maxWXl, bs.textBase)}>
			Before you can fill out the registration form, your Success Manager needs to collect some additional information
			from you to make sure that your privacy policy and opt-in practices conform to the standards required by wireless
			carriers. Please reach out to your Success Manager to complete the pre-vetting process, after which they will
			enable this form.
		</p>
	</RegistrationInfoLayout>
);

export const RegistrationInfoNegative = ({
	feedback,
	showRegisterAgain,
	onRegisterAgainClicked,
	status,
}: {
	feedback?: string[];
	showRegisterAgain: boolean;
	onRegisterAgainClicked?: () => void;
	status: 'failed' | 'declined';
}) => {
	return (
		<RegistrationInfoLayout imageSrc={registrationError}>
			<h2>Sorry!</h2>
			{status === 'declined' ? <p>Your campaign registration has been declined.</p> : null}

			{feedback?.length ? (
				<output className={css(styleSheet.feedbackOutput)}>
					{feedback.map(message => (
						<p className={css(styleSheet.feedbackLine)} key={message}>
							{message}
						</p>
					))}
				</output>
			) : null}
			<p>
				For more information, please contact{' '}
				<a className={css(styleSheet.linkSupport)} href='mailto:support@levitateapp.com'>
					support@levitateapp.com
				</a>
			</p>
			{showRegisterAgain ? (
				<button onClick={onRegisterAgainClicked} className={css(baseStyleSheet.ctaButton)}>
					Register Again
				</button>
			) : null}
		</RegistrationInfoLayout>
	);
};
