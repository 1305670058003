import { embedDashboard } from 'amazon-quicksight-embedding-sdk';
import { MultiContainerHeader } from '../MultiContainerHeader';
// import { LoadingSpinner } from '../LoadingSpinner';
import { css } from 'aphrodite';
import * as React from 'react';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { useDonationAdvancedReportingUrlQuery } from '../../../queries';
import { baseStyleSheet } from '../../styles/styles';
import { LoadingSpinner } from '../LoadingSpinner';
import { styleSheet } from './styles';

const iframeContainerId = 'quicksightAdvancedDonationReportingDashboard';

export const AdvancedDonationReporting = () => {
	const userSession = useUserSession();
	const query = useDonationAdvancedReportingUrlQuery({});

	React.useEffect(() => {
		if (query.data) {
			const parameters = {
				AccountID: userSession.account.id,
			};
			embedDashboard({
				container: document.getElementById(iframeContainerId),
				locale: 'en-US',
				parameters,
				url: `${query.data}`,
			});
		}
	}, [query.data, userSession]);

	return (
		<div className={css(styleSheet.container)}>
			<MultiContainerHeader fullscreenHeader='Advanced Reporting' />
			<div className={css(styleSheet.dashboardContainer)} id={iframeContainerId} />
			{query.isLoading ? <LoadingSpinner type='large' className={css(baseStyleSheet.absoluteCenter)} /> : null}
		</div>
	);
};
