import { StyleSheet } from 'aphrodite';
import { borderColor, header, label, white } from '../../../styles/colors';

const borderStyle = `1px solid ${borderColor}`;

export const styleSheet = StyleSheet.create({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
	},
	row: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 12,
		fontSize: 12,
		background: white,
		border: borderStyle,
		borderRadius: 8,
		boxShadow: '0px 0px 6px rgba(139, 139, 139, 0.1)',
		boxSizing: 'border-box',
		padding: 8,
		width: '100%',
	},
	box: {
		padding: 8,
		width: 100,
		display: 'flex',
		flexDirection: 'column',
		overflowWrap: 'break-word',
	},
	label: {
		color: header,
		fontSize: 12,
		marginBottom: 4,
	},
	emptyText: {
		color: label,
		fontSize: 14,
	},
});
