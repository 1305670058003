import { CampaignType } from '../../../../../../models/AdminModels';
import { useFullscreenModal } from '../../../../../../models/hooks/appStateHooks';
import { MultiContainerHeader } from '../../../../../components/MultiContainerHeader';
import { ComplianceConfirmation } from '../../../../../components/campaigns/ComplianceConfirmation';
import { styleSheet } from './styles';

export function MultiBlogSchedulerComplianceConfirmation() {
	const fullscreenModal = useFullscreenModal();
	return (
		<>
			<MultiContainerHeader
				fullscreenHeader='Blog Schedule Success'
				onFullscreenRequestClose={ev => {
					ev.preventDefault();
					fullscreenModal.dismissModal();
				}}
			/>
			<ComplianceConfirmation
				onDismiss={() => {
					fullscreenModal.dismissModal();
				}}
				multiple
				campaignType={CampaignType.Blog}
				styles={[styleSheet.confirmation]}
			/>
		</>
	);
}
