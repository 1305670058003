import { StyleSheet } from 'aphrodite';
import { brandPrimary, mention } from '../../../styles/colors';

export const styleSheet = StyleSheet.create({
	dashedButton: {
		alignItems: 'center',
		background: mention,
		border: `2px dashed ${brandPrimary}`,
		borderRadius: 8,
		color: brandPrimary,
		display: 'flex',
		gap: 8,
		fontSize: 14,
		height: 42,
		justifyContent: 'center',
		letterSpacing: 0,
		padding: '0 34px 0 34px',
		width: 250,
	},
	icon: {
		marginRight: 10,
	},
});
