import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import {
	IPushNotificationsComponentProps,
	IUserSessionComponentProps,
	UserSessionViewModelKey,
} from '../../../models/AppState';
import { BillingType, NotifyOf } from '../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../styles/styles';
import { Checkbox } from '../Checkbox';
import { asModalComponent } from '../Modal';
import { PushNotificationsPlaceholderIcon } from '../svgs/icons/PushNotificationsPlaceholderIcon';
import { styleSheet } from './styles';

interface IProps
	extends IEventLoggingComponentProps,
		IModalContext<boolean>,
		IUserSessionComponentProps,
		IPushNotificationsComponentProps {
	className?: string;
	styles?: StyleDeclarationValue[];
}

interface IState {
	enablePushNotifications?: boolean;
	showAdditionalHelpText: boolean;
}

class _EnablePushNotifications extends React.PureComponent<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = { showAdditionalHelpText: false };
	}

	public componentDidMount() {
		const { userSession, pushNotifications } = this.props;
		this.setState({
			enablePushNotifications:
				!!pushNotifications && !!pushNotifications.shouldShowPromptDialog
					? userSession.account?.planDetails?.billingType === BillingType.Paid
					: !!userSession.user.userPreferences.notifyOf[NotifyOf.ReadEmails].pushNotifications,
		});
	}

	public render() {
		const { className, styles } = this.props;
		const { enablePushNotifications } = this.state;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} enable-push-notifications ${className || ''}`}>
				<div className={css(styleSheet.body)}>
					<PushNotificationsPlaceholderIcon className={css(styleSheet.icon)} />
					<div className={css(styleSheet.text)}>
						<div className={css(styleSheet.title)}>Wanna know when someone reads your email?</div>
						<div className={css(styleSheet.message)}>
							When you send an email with Levitate or track an email with Levitate, we can notify you when the email is
							being read in real time. We can also let you know of any upcoming birthdays!
						</div>
					</div>
				</div>
				{this.state.showAdditionalHelpText ? (
					<div className={css(styleSheet.additionalHelpContainer)}>
						<div className={css(styleSheet.additionalHelpText)}>
							Having Trouble? Please click &quot;Allow&quot; on the pop up dialog from your browser. <br />
							<br /> If you do not see the pop up please go into your browser or system settings to allow notifications
							for this specific site.
						</div>
					</div>
				) : null}
				<div className={css(styleSheet.footer)}>
					<Checkbox
						checked={!!enablePushNotifications}
						checkedBackgroundFillColor='#fff'
						className={css(styleSheet.rtnCheckbox)}
						id='kit-reminders-setup-real-time-notifications-checkbox'
						onChange={this.onBrowserPushNotificationsCheckboxChanged}
					>
						<span>Allow Levitate to send me real-time notifications for timely events</span>
					</Checkbox>
					<button className={css(baseStyleSheet.ctaButton, styleSheet.okButton)} onClick={this.onOkButtonClicked}>
						<span>Okay</span>
					</button>
				</div>
			</div>
		);
	}

	private onBrowserPushNotificationsCheckboxChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { enablePushNotifications } = this.state;
		if (enablePushNotifications !== e.target.checked) {
			this.setState({
				enablePushNotifications: !!e.target.checked,
			});
		}
	};

	private onOkButtonClicked = () => {
		const { parentModal } = this.props;
		if (parentModal) {
			parentModal.onRequestClose(!!this.state.enablePushNotifications, false);
			setTimeout(() => {
				this.setState({ showAdditionalHelpText: true });
			}, 5000);
		}
	};
}

const EnablePushNotificationsWithContext = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey
)(_EnablePushNotifications);
export const EnablePushNotifications = withEventLogging(EnablePushNotificationsWithContext, 'EnablePushNotifications');

export const EnablePushNotificationsModal = asModalComponent(EnablePushNotifications, {
	shouldCloseOnOverlayClick: false,
	useDefaultHeader: true,
});
