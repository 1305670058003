import { ILocationState } from '@AppModels/.';
import {
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	IErrorMessageComponentProps,
	IFullscreenModalComponentProps,
	ISingleEmailComposerComponentProps,
	IUserSessionComponentProps,
	SingleEmailComposerKey,
	UserSessionViewModelKey,
} from '@AppModels/AppState';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { ActionItemViewModel, ContactViewModel, EmailType, IContact, IOperationResultNoValue } from '@ViewModels';
import { Avatar } from '@WebComponents/Avatar';
import { IMoreMenuItem } from '@WebComponents/MoreMenu';
import { FeedCard, IFeedCardCallbackProps, IFeedCardMoreMenuProps } from '@WebComponents/cards/FeedCard';
import { FullscreenModalNavLink } from '@WebComponents/fullscreen/FullscreenModalNavLink';
import { Tag } from '@WebComponents/tags/Tag';
import { css } from 'aphrodite';
import { computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { kitTintColor } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Button } from '../../Button';
import { AIStarsIcon } from '../../svgs/icons/AIStars';
import { styleSheet } from './styles';

export const KitCardMarkAsCompleteMoreMenuOption: IMoreMenuItem<string> = {
	name: 'Mark as complete',
	representedObject: 'markComplete',
};

export const KitCardChangeFrequencyMoreMenuOption: IMoreMenuItem<string> = {
	name: 'Change frequency',
	representedObject: 'changeFrequency',
};

export const KitCardRemoveTagMoreMenuOption: IMoreMenuItem<string> = {
	name: 'Remove tag from contact',
	representedObject: 'removeTagAlert',
};

const KitCardSuppressMenuOption: IMoreMenuItem<string> = {
	name: 'Remind me later',
	representedObject: 'suppress',
};

interface IProps
	extends IFeedCardCallbackProps,
		ISingleEmailComposerComponentProps,
		IUserSessionComponentProps,
		IEventLoggingComponentProps,
		IErrorMessageComponentProps,
		RouteComponentProps<any>,
		IFeedCardMoreMenuProps<string>,
		IFullscreenModalComponentProps {
	actionItem: ActionItemViewModel;
	className?: string;
	onShowRemoveFromTagDialog?(performRemove: (tagValue: string) => void): void;
	showSuppressOption?: boolean;
}

interface IState {
	actionItem?: ActionItemViewModel;
	redirect?: { pathname: string; state: any };
}

class _KitCard extends React.Component<IProps, IState> {
	public readonly state: IState = {
		actionItem: this.props.actionItem,
	};

	public UNSAFE_componentWillMount() {
		// @ts-ignore
		if (!this.state.actionItem.keepInTouchReference.contact.relationshipHealth) {
			// @ts-ignore
			this.state.actionItem.keepInTouchReference.contact.load();
		}
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
		if (nextProps.actionItem !== this.state.actionItem) {
			this.setState(
				{
					actionItem: nextProps.actionItem,
				},
				() => {
					// @ts-ignore
					if (!this.state.actionItem.keepInTouchReference.contact.relationshipHealth) {
						// @ts-ignore
						this.state.actionItem.keepInTouchReference.contact.load();
					}
				}
			);
		}
	}

	public render() {
		const { className, actionItem } = this.props;
		const { redirect } = this.state;
		const kitRef = actionItem.keepInTouchReference;
		const daysSinceLastInteraction =
			!!kitRef.contact.relationshipHealth && !!kitRef.contact.relationshipHealth.userLastInteractionDate
				? moment().diff(moment(kitRef.contact.relationshipHealth.userLastInteractionDate), 'days')
				: -1;
		const locationState: ILocationState<ContactViewModel, IContact> = {
			viewModel: kitRef.contact,
		};
		return (
			<FeedCard
				className={`${css(styleSheet.container)} kit-card ${className || ''}`}
				indicatorColor={kitTintColor}
				moreMenuItems={this.moreMenuItems}
				onMoreMenuItemClicked={this.onMoreMenuItemClicked}
			>
				<div className={css(styleSheet.header)}>
					{/* @ts-ignore */}
					<Avatar className={css(styleSheet.avatar)} entityVm={kitRef.contact} />
					<div className={css(styleSheet.headerDescription)}>
						<div className={css(styleSheet.headerDescriptionTitle)}>
							Keep in Touch with &nbsp;
							<div className={css(baseStyleSheet.truncateText)}>
								<FullscreenModalNavLink
									className={css(styleSheet.contactName)}
									title={kitRef.contact.name}
									to={{
										pathname: `/people/${kitRef.contact.id}`,
										state: locationState,
									}}
								>
									{kitRef.contact.name}
								</FullscreenModalNavLink>
							</div>
							{kitRef.frequency !== null && kitRef.frequency !== undefined && kitRef.frequency > 0 && (
								<div>
									&nbsp;
									{`every ${kitRef.frequency} month${kitRef.frequency > 1 ? 's' : ''}`}
								</div>
							)}
						</div>
						{!!kitRef.contact.companyName && (
							<div className={css(baseStyleSheet.truncateText, styleSheet.companyName)}>
								{kitRef.contact.companyName}
							</div>
						)}
					</div>
				</div>
				<div className={css(styleSheet.body)}>
					<div className={css(styleSheet.bodyLeft)}>
						{this.visibleTagAlerts.length > 0 && (
							<div className={css(styleSheet.tagAlerts)}>
								{this.visibleTagAlerts.map((x, i) => {
									return (
										<Tag
											className={css(styleSheet.tag)}
											key={`${x.tagValue}-${i}`}
											tagAlert={x}
											tagValue={x.tagValue}
										/>
									);
								})}
								{this.overflowTagAlertsCount > 0 && <span>{`and ${this.overflowTagAlertsCount} more`}</span>}
							</div>
						)}
						{daysSinceLastInteraction > -1 && (
							<div className={css(styleSheet.lastInteraction)}>
								Your last interaction: &nbsp;
								<div className={css(styleSheet.lastInteractionTime)}>
									{daysSinceLastInteraction > 0
										? `${daysSinceLastInteraction} day${daysSinceLastInteraction > 1 ? 's' : ''} ago`
										: 'today'}
								</div>
							</div>
						)}
					</div>
					<div className={css(styleSheet.bodyRight)}>
						<button className={css(baseStyleSheet.ctaButtonSmall)} onClick={this.onSendMessageClicked}>
							<span>Send Email</span>
						</button>
						{this.props.userSession?.account?.features?.contentGeneration?.enabled ? (
							<Button
								kind='reverse'
								size='small'
								onClick={() => this.onShowSendMessageModalWithAI(kitRef.contact)}
								label={
									<span>
										<AIStarsIcon />
										&nbsp; AI Email
									</span>
								}
							/>
						) : null}
					</div>
				</div>
				{!!redirect && <Redirect push={true} to={redirect} />}
			</FeedCard>
		);
	}

	@computed
	private get moreMenuItems() {
		const {
			onShowRemoveFromTagDialog,
			moreMenuItems: propsMoreMenuItems,
			showSuppressOption,
			onSuppressClicked,
		} = this.props;
		// @ts-ignore
		let moreMenuItems: IMoreMenuItem<string>[] = propsMoreMenuItems || null;

		if (!moreMenuItems) {
			moreMenuItems = [];
			if (!!showSuppressOption && !!onSuppressClicked) {
				moreMenuItems.push(KitCardSuppressMenuOption);
			}

			if (this.visibleTagAlerts.length === 0) {
				moreMenuItems = [...moreMenuItems, KitCardMarkAsCompleteMoreMenuOption, KitCardChangeFrequencyMoreMenuOption];
			} else {
				if (onShowRemoveFromTagDialog) {
					moreMenuItems = [...moreMenuItems, KitCardRemoveTagMoreMenuOption];
				}
			}
		}
		return moreMenuItems;
	}

	@computed
	private get visibleTagAlerts() {
		const { actionItem } = this.state;
		// @ts-ignore
		return (actionItem.keepInTouchReference.contact.tagAlerts || []).slice(0, 1);
	}

	@computed
	private get overflowTagAlertsCount() {
		const { actionItem } = this.state;
		// @ts-ignore
		const total = (actionItem.keepInTouchReference.contact.tagAlerts || []).length;
		return total > 1 ? total - 1 : 0;
	}

	private onSendMessageClicked = () => {
		const { logInput, singleEmailComposer } = this.props;
		const { actionItem } = this.state;
		// @ts-ignore
		logInput('SendMessage', 'Click');

		// @ts-ignore
		// @ts-ignore
		singleEmailComposer.showForActionItem(actionItem, {
			onDismiss: this.onEmailModalDismissActionItem,
			onFinish: this.onEmailModalFinished,
		});
	};

	private onShowSendMessageModalWithAI = (contact: ContactViewModel) => {
		const { singleEmailComposer } = this.props;
		singleEmailComposer.showForRecipientWithAI([contact], EmailType.KeepInTouch);
	};

	private onEmailModalDismissActionItem = () => {
		const { onRequestRemove } = this.props;
		if (onRequestRemove) {
			onRequestRemove();
		}
	};

	private onEmailModalFinished = (didSend: boolean) => {
		const { onRequestRemove } = this.props;
		if (!!didSend && !!onRequestRemove) {
			onRequestRemove();
		}
	};

	private onMoreMenuItemClicked = (item: IMoreMenuItem<string>, e: React.MouseEvent<HTMLElement>) => {
		const {
			onShowRemoveFromTagDialog,
			logApiError,
			logInput,
			errorMessages,
			onRequestRemove,
			onMoreMenuItemClicked,
			onSuppressClicked,
			fullscreenModal,
		} = this.props;
		const { actionItem } = this.state;
		if (onMoreMenuItemClicked) {
			onMoreMenuItemClicked(item, e);
		}
		if (!e.defaultPrevented) {
			switch (item.representedObject) {
				case 'removeTagAlert': {
					const removeTag = (tagValue: string) => {
						// @ts-ignore
						const promise = actionItem.keepInTouchReference.contact.removeTags([tagValue]);
						if (promise) {
							// @ts-ignore
							logInput('RemoveTag', 'Click', { tagValue });
							promise
								.then(() => {
									if (this.visibleTagAlerts.length === 0 && !!onRequestRemove) {
										onRequestRemove();
									}
								})
								.catch((error: IOperationResultNoValue) => {
									// @ts-ignore
									errorMessages.pushApiError(error);
									// @ts-ignore
									logApiError('RemoveTag-Error', error);
								});
						}
					};
					// @ts-ignore
					if (actionItem.keepInTouchReference.contact.tagAlerts.length > 1) {
						if (onShowRemoveFromTagDialog) {
							onShowRemoveFromTagDialog(removeTag);
						}
					} else {
						// @ts-ignore
						// @ts-ignore
						removeTag(actionItem.keepInTouchReference.contact.tagAlerts[0].tagValue);
					}
					break;
				}
				case 'changeFrequency': {
					// @ts-ignore
					// @ts-ignore
					logInput('ChangeFrequency', 'Click', { id: actionItem.id });
					const locationState: ILocationState<ContactViewModel, IContact> = {
						// @ts-ignore
						viewModel: actionItem.keepInTouchReference.contact,
					};
					const redirect = {
						// @ts-ignore
						pathname: `/people/${actionItem.keepInTouchReference.contact.id}`,
						state: locationState,
					};
					if (fullscreenModal) {
						fullscreenModal.history.push(redirect);
					} else {
						this.setState({
							redirect,
						});
					}
					break;
				}
				case 'markComplete': {
					// @ts-ignore
					const promise = actionItem.toggleComplete(true);
					if (promise) {
						// @ts-ignore
						// @ts-ignore
						logInput('MarkComplete', 'Click', { id: actionItem.id });
						promise
							.then(() => {
								if (onRequestRemove) {
									onRequestRemove();
								}
							})
							.catch((error: IOperationResultNoValue) => {
								// @ts-ignore
								errorMessages.pushApiError(error);
								// @ts-ignore
								logApiError('MarkComplete-Error', error);
							});
					}
					break;
				}
				case 'suppress': {
					if (onSuppressClicked) {
						onSuppressClicked();
					}
					break;
				}
				default: {
					break;
				}
			}
		}
	};
}

const KitCardAsObserver = observer(_KitCard);
const KitCardWithContext = inject(
	SingleEmailComposerKey,
	ErrorMessagesViewModelKey,
	FullScreenModalViewModelKey,
	UserSessionViewModelKey
)(KitCardAsObserver);
const KitCardWithRouter = withRouter(KitCardWithContext);
export const KitCard = withEventLogging(KitCardWithRouter, 'KitCard');
