import * as React from 'react';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useDonationFilterQuery } from '../../../../../queries';
import { LoadingSpinner } from '../../../LoadingSpinner';
import { styleSheet } from './styles';
import { styleSheet as dbStyleSheet } from '../../../../containers/dataBoards/DataBoardsHome/styles';
import { baseStyleSheet } from '../../../../styles/styles';
import { HeartIcon } from '../../../svgs/icons/HeartIcon';
import { AddBoardItemButton } from '../../../boards/AddBoardItemButton';
import Waypoint from 'react-waypoint';
import { dataGridStyles } from '../../../DataGrid/data-grid-styles';
import { numberToCurrencyStringValue } from '../../../../../models/UiUtils';
import { Badge } from '../../../dataBoards/Badge';
import moment from 'moment';
import { EditDonationModal } from '../../../../containers/dataBoards/DonationsBoard/EditDonationModal/';

export const EntityDonations = ({
	entity,
}: {
	entity: Api.IEntity;
}) => {
	const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);
	const [refreshToken, setRefreshToken] = React.useState(new Date().getTime());
	const donationFilterQuery = useDonationFilterQuery({
		enabled: !!entity.id,
		filterRequest: {
			criteria: [
				{
					property: Api.DonationFilterProperty.ContactId,
					value: entity.id,
				},
			],
		},
	});

	const donations = React.useMemo(() => {
		return donationFilterQuery.data?.pages.map(page => page.values).flat() ?? [];
	}, [donationFilterQuery.data]);

	const onFetchNextBatchOfDonations = () => {
		donationFilterQuery.fetchNextPage();
	};

	const addItemClicked = () => {
		setIsAddModalOpen(true);
	};

	const refreshDonations = () => {
		setRefreshToken(new Date().getTime());
		setIsAddModalOpen(false);
	};

	const newDonation = {
		contact: entity,
	};

	if (donationFilterQuery.isLoading) {
		return <LoadingSpinner />;
	}

	return (
		<div key={refreshToken}>
			<div className={css(styleSheet.contentSection)}>
				<header className={css(styleSheet.contentHeader)}>
					<div className={css(dbStyleSheet.boardIconContainer, baseStyleSheet.bgPink)}>
						<HeartIcon height={24} width={24} className={css(styleSheet.donationsIcon)} />
					</div>
					<h6 className={css(styleSheet.contentListTitle)}>Donations</h6>
					<AddBoardItemButton className={css(styleSheet.contentAddButton)} onClick={addItemClicked}>
						Add Donation
					</AddBoardItemButton>
				</header>

				{!donations.length ? <p className={css(styleSheet.emptyText)}>No donations exist for this contact.</p> : null}

				{donations.length ? (
					<>
						<table className={css(dataGridStyles.table, styleSheet.tableOverrides)}>
							<tbody>
								{donations.map(donation => {
									const borderlessRow = donation === donations[0];

									const rowStyles = borderlessRow
										? dataGridStyles.tableDataCellBorderless
										: dataGridStyles.tableDataCell;

									return (
										<tr key={`donation-${donation.id}`}>
											<td className={css(rowStyles)}>
												<span className={css(styleSheet.amountText)}>
													${numberToCurrencyStringValue(donation.amount)}
												</span>
											</td>
											<td className={css(rowStyles)}>
												{donation.campaign ? (
													<Badge text={donation.campaign.name} foregroundColor={donation.campaign.foregroundColor} />
												) : null}
											</td>
											<td className={css(rowStyles)}>
												<span>{moment(donation.date).format('MM/DD/YYYY')}</span>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
						<Waypoint bottomOffset='-200px' onEnter={onFetchNextBatchOfDonations} />
					</>
				) : null}
			</div>

			{isAddModalOpen ? (
				<EditDonationModal
					initialDonation={newDonation as Api.IDonation}
					isOpen={isAddModalOpen}
					setIsOpen={setIsAddModalOpen}
					onSave={refreshDonations}
					onCancel={refreshDonations}
				/>
			) : null}
		</div>
	);
};
