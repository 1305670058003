import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom';
import { IModalContext, ModalChildComponentContextKey } from '../../../models';
import { Topics } from '../../../models/LocalNotificationTopics';
import { postNotification } from '../../../models/LocalNotifications';
import { useEventLogging } from '../../../models/Logging';
import { useErrorMessages, useToaster } from '../../../models/hooks/appStateHooks';
import {
	ComposeEmailViewModel,
	ContentCalenderSuggestionViewModel,
	IEmailMessageFollowUpOptions,
} from '../../../viewmodels/AppViewModels';
import { SuggestedCampaignOptionsSummary } from '../../components/campaigns/SuggestedCampaignOptionsSummary';
import { EditCampaign } from '../templates/EditCampaign';
import { Search } from '../templates/Search';
import { styleSheet } from './styles';

interface IProps extends Partial<RouteComponentProps<any>>, IModalContext {
	className?: string;
	emailComposer?: ComposeEmailViewModel<IEmailMessageFollowUpOptions>;
	onFinish?(): void;
	styles?: StyleDeclarationValue[];
	suggestion?: ContentCalenderSuggestionViewModel;
}

const SuggestedCampaignComposerBase: React.FC<IProps> = observer(props => {
	const { className, styles, emailComposer, onFinish, suggestion } = props;
	const toaster = useToaster();
	const errorMessages = useErrorMessages();
	const { logApiError } = useEventLogging('SuggestedCampaignComposer');
	const h = useHistory();
	const match = useRouteMatch();

	// #region navigation
	const onShowSendOptionsClicked = React.useCallback(() => {
		h.push({
			pathname: `${match.url}/schedule`,
			state: h.location.state,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.url, h.location.state]);

	const onEditRecipientsClicked = React.useCallback(() => {
		h.push({
			pathname: `${match.url}/recipients`,
			state: h.location.state,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [match.url, h.location.state]);
	// #endregion

	// #region send
	const approveSuggestion = React.useCallback(async () => {
		try {
			// @ts-ignore
			// @ts-ignore
			await emailComposer.emailMessage.send(suggestion.id);
			// @ts-ignore
			toaster.push({
				message: `Suggestion Approved`,
				type: 'successMessage',
			});
			postNotification({
				info: [],
				topic: Topics.EDIT_CAMPAIGNS_ITEM,
			});
			// @ts-ignore
			onFinish();
		} catch (error) {
			// @ts-ignore
			logApiError('ApproveSuggestion-Error', error);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailComposer, suggestion, onFinish]);

	const approveWithCompliance = React.useCallback(async () => {
		try {
			// @ts-ignore
			emailComposer.emailMessage.options.sendWithCompliance = true;
			// @ts-ignore
			// @ts-ignore
			await emailComposer.emailMessage.send(suggestion.id);
			// @ts-ignore
			toaster.push({
				message: `Suggestion Approved`,
				type: 'successMessage',
			});
			postNotification({
				info: [],
				topic: Topics.EDIT_CAMPAIGNS_ITEM,
			});
			// @ts-ignore
			onFinish();
		} catch (error) {
			// @ts-ignore
			logApiError('ApproveWithComplianceSuggestion-Error', error);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailComposer, suggestion, onFinish]);

	const rejectSuggestion = React.useCallback(async () => {
		try {
			// @ts-ignore
			await suggestion.reject();
			// @ts-ignore
			toaster.push({
				message: `Suggestion Rejected`,
				type: 'successMessage',
			});
			postNotification({
				info: [],
				topic: Topics.EDIT_CAMPAIGNS_ITEM,
			});
			// @ts-ignore
			onFinish();
		} catch (error) {
			// @ts-ignore
			logApiError('RejectSuggestion-Error', error);
			// @ts-ignore
			// @ts-ignore
			errorMessages.pushApiError(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [suggestion, onFinish]);
	// #endregion

	const onFilterCriteriaUpdated = React.useCallback(
		explicitelyPreventContactFilterRequest => {
			if (explicitelyPreventContactFilterRequest) {
				emailComposer?.setAllowContactFilterTagSearchChangesOverride(false);
			}
			suggestion.userSelectedNewTags = true;
		},
		[emailComposer, suggestion]
	);

	const onContextFinishedEditingClicked = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		h.goBack();
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} suggested-campaign-composer ${className || ''}`}>
			<EditCampaign
				// @ts-ignore
				emailComposer={emailComposer}
				isQueuedCampaign={true}
				initialEmailBodyTemplate={emailComposer?.suggestion?.template}
				onContextFinishedEditingClicked={onContextFinishedEditingClicked}
				readonlyContent={false}
				styles={[styleSheet.editCampaign]}
			>
				{suggestion?.accountTagsMatchingFilterCriteriaTags?.length || suggestion?.userSelectedNewTags ? (
					h.location.pathname === match.url && (
						<SuggestedCampaignOptionsSummary
							emailComposer={emailComposer}
							onApproveClicked={approveSuggestion}
							onApproveWithCompliance={approveWithCompliance}
							onChangeScheduleClicked={onShowSendOptionsClicked}
							onEditRecipientsClicked={onEditRecipientsClicked}
							onRejectClicked={rejectSuggestion}
						/>
					)
				) : (
					<Search applyOwnedByCriteria={false} nextCtaText='Next: Summary' onNextClick={onFilterCriteriaUpdated} />
				)}
			</EditCampaign>
		</div>
	);
});

export const SuggestedCampaignComposer = inject(ModalChildComponentContextKey)(SuggestedCampaignComposerBase);
