import { Switch, useLocation } from 'react-router-dom';
import { useUserSession } from '../../../models/hooks/appStateHooks';
import { MainContainerPageShell } from '../../components/AppShell';
import { PrivateRoute } from '../../components/PrivateRoute';
import { DataBoardsHome } from './DataBoardsHome';
import { DonationsBoard } from './DonationsBoard';
import { OpportunitiesBoard } from './OpportunitiesBoard';
import { Opportunity } from './Opportunity';
import { PoliciesBoard } from './PoliciesBoard';

export const DataBoardsRoute = () => {
	const userSession = useUserSession();
	const location = useLocation();
	const showDonations = userSession.account.features?.boards?.donationsEnabled;
	const showPolicies = userSession.account.features?.boards?.policiesEnabled;

	return (
		<Switch location={location}>
			<PrivateRoute
				path='/dataBoards/opportunities/:boardId/:id'
				pageViewLogName='opportunity'
				userSession={userSession}
			>
				<Opportunity routeContainerClassName='main-container-child' />
			</PrivateRoute>

			<PrivateRoute path='/dataBoards/opportunities/:id' pageViewLogName='opportunities' userSession={userSession}>
				<OpportunitiesBoard routeContainerClassName='main-container-child' />
			</PrivateRoute>

			{showDonations ? (
				<PrivateRoute path='/dataBoards/donations' pageViewLogName='donations' userSession={userSession}>
					<MainContainerPageShell>
						<DonationsBoard />
					</MainContainerPageShell>
				</PrivateRoute>
			) : null}

			{showPolicies ? (
				<PrivateRoute path='/dataBoards/policies' pageViewLogName='policies' userSession={userSession}>
					<MainContainerPageShell>
						<PoliciesBoard />
					</MainContainerPageShell>
				</PrivateRoute>
			) : null}

			<PrivateRoute userSession={userSession}>
				<MainContainerPageShell compactOnly={true}>
					<DataBoardsHome />
				</MainContainerPageShell>
			</PrivateRoute>
		</Switch>
	);
};
